////
/// ============================================================================
/// ARCHIVE
///
/// 01. Layout
/// 02. Archive List
/// 03. Magazine
/// 04. Catalogo
/// ============================================================================
///
/// @group pages
////

/**
 * Layout
 */
.o-app {
	margin-left: auto;
	margin-right: auto;
	max-width: 1280px;

	&__title {
		text-align: center;
		@include media(">=l") {
			margin-left: auto;
			margin-right: auto;
			max-width: 60%;
		}
	}

	&__text {
		@include media(">=l") {
			margin-left: auto;
			margin-right: auto;
			max-width: 80%;
		}
	}
}


// Beta
.c-app-beta {
	background: #dacf11;
	border-radius: 10em;
	color: #fff;
	display: inline-block;
	font-size: 14px;
	letter-spacing: 1px;
	line-height: 18px;
	margin-left: 5px;
	padding: 5px 8px;
	text-align: center;
	text-transform: uppercase;
	@include media(">=m") {
		top: -2.5em;
	}
}


// Form
.c-app-form {
	&__field {
		&.-data {
			input {
				padding: 1.45rem 1em;
			}
		}

		&.-target {
			.c-app-option {
				background: #000;
				color: #fff;
				padding: 10rem 0.5rem 0.5rem 0.5rem;

				&__title {
					font-size: 1.6em;
					line-height: 1;
				}

				&__image {
					@include absolute(top 0 left 0);
					height: 100%;
					width: 100%;
				}
			}
		}
	}

	&__submit {
		text-align: center;

		.btn {
			font-size: 1.8em;
			padding: 0.8em 2em;
		}
	}
}

// Grid
// @see https://css-tricks.com/putting-the-flexbox-albatross-to-real-use/
.c-app-grid {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 0 calc(1rem * -1);

	&__item {
		flex-basis: calc(560px * 999 - 100% * 999);
		//flex-grow: 1;
		margin: 1rem;
		max-width: 100%;
		min-width: calc((100% / 3) - (1rem * 2));

		.-four & {
			min-width: calc((100% / 2) - (1rem * 2));
			@include media(">=m") {
				min-width: calc((100% / 4) - (1rem * 2));
			}
		}

		.-five & {
			flex-basis: calc(320px * 999 - 100% * 999);
			min-width: calc((100% / 2) - (1rem * 2));
			@include media(">=s") {
				min-width: calc((100% / 3) - (1rem * 2));
			}
			@include media(">=m") {
				min-width: calc((100% / 5) - (1rem * 2));
			}
		}

		.-six & {
			flex-basis: calc(320px * 999 - 100% * 999);
			min-width: calc((100% / 2) - (1rem * 2));
			@include media(">=s") {
				min-width: calc((100% / 3) - (1rem * 2));
			}
			@include media(">=m") {
				min-width: calc((100% / 6) - (1rem * 2));
			}
		}
	}
}


// Option
.c-app-option {
	background: #fff;
	border: 1px solid color(gray-light);
	border-radius: $border-radius;
	cursor: pointer;
	display: block;
	padding: 2rem;
	position: relative;
	text-align: center;
	transition: all 0.3s;

	&.-checked,
	&:hover {
		border-color: color(primary, base);
		box-shadow: 0 0 20px rgba(#000, 0.1), inset 0 0 30px rgba(#000, 0.05);
	}

	.-five &,
	.-six & {
		padding: 1rem;
	}

	&.-ghost {
		border: 0;

		&::after {
			@include absolute(top 0 right -1rem);
			background: color(gray-light);
			content: '';
			height: 100%;
			width: 1px;
		}

		&:last-of-type {
			&::after {
				display: none;
			}
		}

		&:hover {
			box-shadow: none;
		}
	}

	&__title {
		@include typography($h5);
		font-weight: fontweight-get(bold);
		position: relative;
		z-index: 1;

		&.-big {
			@include typography($h3);
		}
	}

	&__image {
		z-index: 0;

		&.-cover {
			background: #000;
			border-radius: $border-radius;
			filter: grayscale(0.4) saturate(0.8);
			opacity: 0.9;
			overflow: hidden;

			&::before {
				@include absolute(top 0 left 0);
				background: radial-gradient(ellipse at bottom left, rgba(#000, 1) 0%, rgba(#000, 0) 100%);
				content: '';
				height: 100%;
				width: 100%;
			}

			img {
				height: 100%;
				object-fit: cover;
				width: 100%;

				.no-object-fit & {
					@include absolute(top 0 left 50%);
					max-width: none;
					transform: translateX(-50%);
					width: auto;
				}
			}
		}
	}

	&__description {
		line-height: 1.2;
		margin-top: 1rem;
		position: relative;
		z-index: 1;
	}

	&__icon {
		height: 100px;
		position: relative;
		z-index: 1;
	}

	&__value {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		& > div {
			flex-grow: 1;
			margin-right: 0.2em;
			min-width: 140px;
			padding: 0 0.5em;

			&:last-of-type {
				margin-right: 0;
			}
		}
	}
}


// Radio Button
// @see https://codyhouse.co/ds/components/app/custom-checkbox
.c-app-radio {
	display: inline-block;
	font-size: 30px; // Radio size
	margin-top: 1rem;
	position: relative;
	z-index: 1;

	input[type="radio"] {
		border: 0;
		box-shadow: none;
		// hide input
		cursor: pointer;
		display: block;
		font-size: inherit;
		height: 1em;
		margin: 0;
		opacity: 0;
		padding: 0;
		// Reset
		position: relative;
		width: 1em;
		z-index: 1;
	}

	& > div {
		@include absolute(top 0 left 0, 0);
		color: color(gray-light);
		height: 100%;
		pointer-events: none;
		transition: transform 0.2s;
		width: 100%;

		// custom radio
		&::before {
			@include absolute(top 0 left 0);
			background-color: #fff;
			border-radius: 50%;
			box-shadow: inset 0 0 0 2px color(gray-light);
			content: '';
			height: 100%;
			width: 100%;
		}
	}

	input[type="radio"]:checked ~ div {
		color: #fff;

		&::before {
			background-color: currentColor;
			background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cg fill='%23c32d32'%3E%3Ccircle cx='8' cy='8' r='8' fill='%23c32d32'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
			background-position: center;
			background-repeat: no-repeat;
			background-size: 0.55em;
			box-shadow: inset 0 0 0 2px color(primary, base);
		}
	}

	input[type="radio"]:active ~ div {
		transform: scale(0.9);
	}

	input[type="radio"]:checked:active ~ div {
		transform: scale(1);
	}

	// Icon
	&.-icon {
		border: 1px solid color(gray-light);
		font-size: 64px;
		margin-top: 0;

		div::before {
			display: none;
		}

		svg {
			color: inherit;
			display: block;
			position: relative;
			top: 0;

			// Reset
			z-index: 1;
		}

		input[type="radio"] {
			width: 100%;
		}

		input[type="radio"]:checked ~ div {
			color: color(text, base);
		}
	}
}


// Input Group
.c-input-group {
	align-items: stretch;
	display: flex;
	width: 100%;

	&__label {
		align-items: center;
		border: 1px solid color(gray-light);
		color: color(gray);
		display: flex;
		padding: 0.7rem 1em;
		text-align: center;
		white-space: nowrap;

		&:first-child {
			border-right: 0;
		}

		&:last-child {
			border-left: 0;
		}
	}

	&__field {
		border-radius: 0;
		flex: 1 1 0;
		margin: 0;
		min-width: 0;
		white-space: nowrap;

		&:first-child {
			border-right: 0;
		}

		&:last-child {
			border-left: 0;
		}
	}
}

// Radio Group
.c-radio-group {
	display: flex;
	justify-content: space-between;

	& > label {
		flex-grow: 1;
		margin-right: 0.2em;

		&:last-of-type {
			margin-right: 0;
		}
	}
}


// Results
.c-app-results {
	display: none;
	margin-top: 2rem;

	&.-show {
		display: block;
	}
}

.c-app-result {
	&__number {
		@include typography($h3);
		color: color(primary, base);
		display: block;
		font-weight: fontweight-get(bold);

		&.-large {
			font-size: 5em;
		}
	}

	&__value {
		display: block;
		font-weight: fontweight-get(bold);
	}
}


// Tooltip
// @see https://codyhouse.co/ds/components/app/tooltip
.c-tooltip {
	background-color: lighten(color(warning), 10%);
	border-radius: $border-radius;
	color: color(text, base);
	display: inline-block;
	font-size: 14px;
	line-height: 1.2;
	max-width: 200px;
	padding: 15px;
	pointer-events: none;
	position: absolute;
	transition: opacity 0.2s, visibility 0.2s;
	z-index: 10;

	// class used in js to hide the tooltip element before its top/left positions are set
	&--is-hidden {
		opacity: 0;
		visibility: hidden;
	}

	// trigger
	&-trigger {
		//white-space: nowrap;
		//cursor: help;
	}

	// triangle
	&::before {
		border: 8px solid transparent;
		content: '';
		height: 0;
		position: absolute;
		width: 0;
	}

	&--top::before {
		border-top-color: lighten(color(warning), 10%);
		bottom: 0;
		left: 50%;
		transform: translateY(100%) translateX(-50%);
	}

	&--bottom::before {
		border-bottom-color: lighten(color(warning), 10%);
		left: 50%;
		top: 0;
		transform: translateY(-100%) translateX(-50%);
	}

	&--left::before {
		border-left-color: lighten(color(warning), 10%);
		left: 100%;
		top: 50%;
		transform: translateY(-50%);
	}

	&--right::before {
		border-right-color: lighten(color(warning), 10%);
		left: 0;
		top: 50%;
		transform: translateY(-50%) translateX(-100%);
	}
}


// Help
.c-app-help {
	margin-left: auto;
	margin-right: auto;
	max-width: 800px;
	text-align: center;

	h4 {
		font-weight: fontweight-get(bold);
		margin-bottom: 0;
	}

	p {
		color: color(error);
	}
}
