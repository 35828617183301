////
/// ============================================================================
/// HELP
///
/// 01. Clearfix
/// 02. Display
/// 03. Font Family Placeholder
/// ============================================================================
///
/// @group components
////

/**
 * Clearfix
 */
.clearfix, .cf {
	@include clearfix;
}


/**
 * Display
 */
.display {
	&-hidden {
		@include hide(hidden);
	}

	&-accessibility {
		@include hide(visuallyhidden);
	}
}