////
/// ============================================================================
/// PAGINATION
/// ============================================================================
///
/// @group components
////

/**
 * Pagination
 */
.c-pagination {
    @include clearfix;
    text-align: center;
    &__current,
    &__url,
    &__dots {
        @include aspect-ratio(1 1);
        border: 1px solid color(gray-light);
        align-items: center;
        color: color(text, base);
        display: inline-flex;
        justify-content: center;
        line-height: 3rem;
        margin-left: -1px;
        vertical-align: middle;
        width: 3em;
    }
    &__url {
        color: color(text, base);
        cursor: pointer;
        &:hover {
            background: color(gray-darker);
            border-color: color(gray-darker);
            color: #fff;
        }
    }
    &__current {
        background: color(primary, base);
        border-color: color(primary, base);
        color: #fff;
    }

    svg {
        height: 1em;
        width: 1em;
    }
}
