////
/// ============================================================================
/// ADV
///
/// 01. Header
/// 02. Sidebar
///
/// ============================================================================
///
/// @group components
////

/**
 * Adv
 */
.adv {
    a {
        display: block;
    }

    /* Header */
    &-header {
        text-align: right;
        img {
            border: 1px solid color(gray-light);
        }
        &__item {
            display: none;
            &--mobile {
                display: block;
                margin-bottom: 1rem;
                margin-top: 1rem;
                @include media(">=m") {
                    display: none;
                }
            }
            &--dektop {
                @include media(">=m") {
                    display: block;
                    padding-left: 50px;
                }
                @include media(">=l") {
                    padding-left: 150px;
                }
                @include media(">=xl") {
                    padding-left: 300px;
                }
            }
        }
    }

    /* Sidebar */
    &-sidebar {

    }
}

