////
/// ============================================================================
/// STRIPES
/// ============================================================================
///
/// @link https://stripe.com/blog/connect-front-end-experience Ispiration
/// @link https://codepen.io/mrtrimble/pen/pEZJLY Ispiration
///
/// @group components
////

.stripes {
    @include absolute(top 50% left 50%, 0);
    display: grid;
    grid: repeat(5, 350px) / repeat(10, 1fr);
    max-height: 100%;
    transform: skewY(-12deg) translate3d(-50%, -50%, 0);
    transform-origin: 0;
    width: 100%;

    span {
        &:nth-child(1) {
            background: linear-gradient(100grad, darken(color(gray-lighter), 5%), darken(color(gray-lighter), 3%) 40%, rgba(color(gray-lighter), 0));
            grid-column: span 5;
        }
        &:nth-child(2) {
            background: linear-gradient(100grad, rgba(color(gray-lighter), 0), darken(color(gray-lighter), 3%) 40%, darken(color(gray-lighter), 4%));
            grid-area: 2 / span 6 / auto / -1
        }
        &:nth-child(3) {
            background: linear-gradient(100grad, darken(color(gray-lighter), 5%), darken(color(gray-lighter), 3%) 40%, rgba(color(gray-lighter), 0));
            grid-area: 3 / span 3 / auto / -1;
            grid-column: span 5;
        }
        &:nth-child(4) {
            background: linear-gradient(100grad, rgba(color(gray-lighter), 0), darken(color(gray-lighter), 3%) 40%, darken(color(gray-lighter), 4%));
            grid-area: 4 / span 3 / auto / -1;
        }
    }

    &--dark {
        span {
            opacity: 0.6;
            &:nth-child(1),
            &:nth-child(3) {
                background: linear-gradient(100grad, lighten(color(gray-darker), 10%), lighten(color(gray-darker), 5%) 40%, rgba(color(gray-darker), 0));
            }
            &:nth-child(2),
            &:nth-child(4) {
                background: linear-gradient(100grad, rgba(color(gray-darker), 0), lighten(color(gray-darker), 5%) 40%, darken(color(gray-darker), 4%));
            }
        }
    }
}
