////
/// ============================================================================
/// CUSTOM - ENTRY
///
/// 01. Default
/// 02. Entry Post
/// 02. Entry Product
/// 02. Entry Card
/// ============================================================================
///
/// @group components
////

/**
 * Default
 */
.entry {
	width: 100%;

	&__image {
		display: block;
		position: relative;

		figure {
			height: 100%;
			width: 100%;

			img {
				height: 100%;
			}
		}
	}

	&__content {
		display: block;
	}

	&__meta {
		font-size: 12px;
		line-height: 12px;
		list-style: none;
		margin-bottom: 0.5rem;
		margin-top: 0;
		padding-left: 0;

		li {
			display: inline-block;
			text-transform: uppercase;
		}

		a {
			text-decoration: none;
		}
	}

	&__title {
		margin-bottom: 0.5rem;
		margin-top: 0;

		a {
			color: color(text, base);
		}
	}

	&__excerpt {
		margin-bottom: 0;
		margin-top: 1rem;
	}

	&__cart {
		margin-top: 1rem;

		.price {
			display: block;
			margin-bottom: 0.5rem;

			.from,
			.subscription-details {
				display: block;
				font-size: 14px;
				font-weight: 400;
				width: 100%;
			}

			del {
				margin-right: 0.5em;
			}
		}

		.button {
			width: auto;
		}
	}

	.swiper-wrapper & {
		margin-bottom: 0;
		margin-top: 0;
	}
}

/**
 * Entry Post
 */
.entry-post {
	align-items: flex-start;
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	margin-top: 2rem;

	&:first-child {
		margin-top: 0;
	}

	&__title {
		@include typography($h5);
	}

	&__image {
		width: 35%;
	}

	&__content {
		width: 60%;
	}
}

/**
 * Entry Product Book
 */
.entry-product,
.entry-book,
.entry-magazine {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 4rem;
	text-align: center;
	width: 100%;

	&:last-of-type {
		margin-bottom: 0;
	}

	@include media(">=s") {
		text-align: left;
	}

	&__image {
		margin-bottom: 2rem;
		width: 100%;
		@include media(">=s") {
			flex: 0 0 auto;
			margin-bottom: 0;
			width: 30%;
		}

		figure {
			position: relative;
			z-index: 1;
		}

		&:after {
			@include absolute(right 1.5em bottom 0 left 1.5em, 0);
			box-shadow: 0 20px 30px -5px rgba(0, 0, 0, .2);
			content: '';
			display: block;
			height: 30px;
		}
	}

	&__content {
		width: 100%;
		@include media(">=s") {
			flex: 1 1 auto;
			padding-left: 2em;
			width: 70%;
		}
	}

	&__title {
		.woocommerce-MyAccount-content & {
			font-weight: fontweight-get(regular);
			margin-bottom: 0;
		}
	}

	&__subtitle {
		margin-bottom: 0;
	}

	&__cart {
		.cart {
			align-items: center;
			display: flex;
			justify-content: center;
			margin-bottom: 0;

			&.bundle_form {
				display: block;

				.bundled_products thead {
					display: none;
				}

				.bundled_product_title {
					font-size: 1em;
					margin-bottom: 0;
					margin-top: 0;
				}

				.bundled_product_price {
					font-size: 1em;
				}
			}

			@include media(">=s") {
				justify-content: flex-start;
			}

			.quantity {
				align-items: center;
				display: flex;
				float: left;
				padding-right: 1em;
				width: 80px;

				.qty {
					padding-left: 0.5em;
					padding-right: 0.5em;
					text-align: center;
					width: 100%;
				}

				&.hidden {
					display: none;
				}
			}

			.button {
				flex: 0 1 auto;
			}
		}
	}

	.button.added {
		display: none;
	}

	.swiper-wrapper & {
		width: 60%;
	}

	.sidebar & {
		.entry__image,
		.entry__content {
			padding: 0;
			text-align: center;
			width: 100%;
		}

		.star-rating,
		.entry__title,
		.entry__subtitle {
			display: none;
		}
	}
}