////
/// ============================================================================
/// FORM
///
/// 01. Input, Select, Textarea
/// 02. Textarea
/// 03. Placeholder, Disabled, Readonly
/// 04. Select
/// 05. Checkbox, Radio, File
/// 06. Label
/// ============================================================================
///
/// @group components
////

/**
 * Input, Select, Textarea
 */
input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="color;"],
select,
textarea {
	-moz-appearance: none;
	-webkit-appearance: none;
	background-color: #fff;
	border: 1px solid color(gray-light);
	border-radius: 0;
	box-shadow: none;
	color: color(text, base);
	display: block;
	font-family: inherit;
	font-size: 1rem;
	outline: 0;
	padding: 0.7rem 1em;
	transition: border-color 0.25s ease-in-out;
	width: 100%;

	&:focus {
		background-color: #fff;
		border: 1px solid darken(color(gray-lighter), 10%);
		outline: none;
		transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
	}
}


/**
 * Textarea
 */
textarea {
	max-width: 100%;
	resize: vertical;

	&[rows] {
		height: auto;
	}
}


/**
 * Placeholder, Disabled, Readonly
 */
input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="color;"],
textarea {
	&::placeholder {
		color: color(text, light);
	}

	&:disabled,
	&[readonly] {
		background-color: lighten(color(gray), 20%);
		cursor: default;
	}
}


/**
 * Select
 */
select {
	cursor: pointer;

	&:not([multiple]) {
		background-image: url(data:image/png;base64,R0lGODlhDwAUAIABAAAAAP///yH5BAEAAAEALAAAAAAPABQAAAIXjI+py+0Po5wH2HsXzmw//lHiSJZmUAAAOw==);
		background-position: 99% 50%;
		background-repeat: no-repeat;
		padding-right: 1em;
	}
}


/**
 * Checkbox, Radio, File
 */
form {
	[type="checkbox"],
	[type="radio"] {
		box-sizing: border-box;
		display: inline-block;
		padding: 0;
		width: auto;

		& + label {
			display: inline-block;
			margin-bottom: 0;
			margin-left: 0.5rem;
			margin-right: 1rem;
			vertical-align: baseline;

			&[for] {
				cursor: pointer;
			}
		}
	}

	[type="checkbox"] {
		-moz-appearance: checkbox;
		-webkit-appearance: checkbox;
	}

	[type="radio"] {
		-moz-appearance: radio;
		-webkit-appearance: radio;
	}

	[type="file"] {
		width: 100%;
	}

	[type='file'],
	[type='checkbox'],
	[type='radio'] {
		margin: 0;
	}
}


/**
 * Label
 */
label {
	color: color(text, base);
	display: block;
	font-weight: fontweight-get(regular);
	line-height: 2;
	margin: 0;
	position: relative;

	&.radio {
		display: inline;
		margin-right: 20px;
	}
}
