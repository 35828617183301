////
/// ============================================================================
/// FOOTER
///
/// 01. Footer
/// ============================================================================
///
/// @group layout
////

/**
 * Footer
 */
.footer {
	ul {
		@include reset(list);
		@include clearfix;
	}

	h4 {
		font-weight: fontweight-get(bold);
		margin-bottom: vr(1, $map: $h4);
		text-transform: uppercase;
	}

	&__newsletter {
		@include clearfix;
		background: color(gray-darker);
		color: #fff;
	}

	&__close {
		&, a {
			color: color(text, light);
			text-decoration: none;
		}
	}

	&__sitemap {
		@include clearfix;

		h4 {
			margin-top: 0;
		}

		ul, p {
			font-size: 15px;
			margin: 0;
		}

		@include media(">=m") {
			align-items: center;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
	}

	&__disclaimer,
	&__copyright {
		@include clearfix;
		border-top: 1px solid color(gray-light);
		font-size: 15px;
		@include media(">=m") {
			align-items: center;
			display: flex;
		}
	}
}


/**
 * Footer Newsletter
 */
.newsletter {
	@include clearfix;
	margin-bottom: 0;
	@include media(">=l") {
		align-items: center;
		display: flex;
		justify-content: space-between;
	}

	&__header {
		margin-bottom: 2rem;

		h2 {
			color: #fff;
		}

		@include media(">=l") {
			@include span(5);
			@include suffix(1);
			margin-bottom: 0;
		}
	}

	&__form {
		@include media(">=l") {
			@include span(last 7);
		}
	}
}


/**
 * Footer Block
 */
.footer-block {
	@include clearfix();
}

.about {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 2rem;
	@include media(">=m") {
		flex: 0 1 auto;
		margin-bottom: 0;
		padding-right: gutter();
		width: 50%;
	}

	&__video {
		margin-bottom: 1rem;
		text-align: center;
		@include media(">=s") {
			float: left;
			margin-bottom: 0;
			width: 40%;
		}

		a {
			display: block;
			position: relative;
		}
	}

	&__content {
		@include media(">=s") {
			padding-left: 5%;
			width: 60%;
		}
	}
}

.fbpage {
	text-align: center;
	@include media(">=m") {
		flex: 0 0 auto;
		text-align: right;
		width: 50%;
	}
}


/**
 * Footer Copyright
 */
.footer-copyright {
	align-items: center;
	display: flex;
	justify-content: center;
	margin-bottom: 1rem;
	@include media(">=m") {
		@include span(9.5);
		justify-content: flex-start;
		margin-bottom: 0;
	}

	&__logo {
		flex-shrink: 0;
		height: 50px;
		margin-right: 1em;
		width: 40px;
	}
}


/**
 * Footer Social
 */
.footer-social {
	text-align: center;
	@include media(">=m") {
		@include span(last 2.5);
		text-align: right;
	}

	&__item {
		display: inline-block;
		margin-right: 3px;
		text-align: center;

		&:last-child {
			margin-right: 0;
		}

		a {
			$size: 40px;
			border: 1px solid currentColor;
			display: block;
			height: $size;
			line-height: $size - 2px;
			text-decoration: none;
			width: $size;

			svg {
				height: 24px;
				width: 24px;
			}
		}

		/**
		 * Color Map
		 * @link http://brandcolors.net/
		 */
		$socials: (
				facebook: #3b5998,
				feed: #f62,
				twitter: #55acee,
				youtube: #cd201f
		);

		@each $name, $color in $socials {
			&.#{$name} a {
				background: #{$color};
				border-color: #{$color};
				color: #fff;
			}
		}
	}
}
