////
/// ============================================================================
/// WOOCOMMERCE TABLE
/// ============================================================================
///
/// @group woocommerce
///

.woocommerce {
	/**
	 * Default
	 */
	table {
		border-collapse: separate;
		border-spacing: 0;
		color: color(text, light);
		margin: 0 0 2rem;
		width: 100%;

		caption {
			font-weight: 600;
			padding: 1em 0;
		}

		td, th {
			padding: 1em 1.5em;
			text-align: left;
			vertical-align: top;

			p:last-child {
				margin-bottom: 0;
			}
		}

		th {
			background-color: #f8f8f8;
			font-weight: fontweight-get(bold);
		}

		thead th {
			padding: 1.5em;
			vertical-align: middle;
		}

		tbody {
			td {
				background-color: #fdfdfd;
			}

			tr:nth-child(2n) td {
				background-color: #fbfbfb;
			}

			h2 {
				font-size: 1em;
				font-weight: 400;
				letter-spacing: normal;
			}
		}

		a {
			text-decoration: none;
		}

		/**
		 * Responsive settings
		 */
		&.shop_table_responsive {
			thead,
			tbody th {
				display: none;
			}

			tr td {
				clear: both;
				display: block;
				text-align: right;

				&:before {
					content: attr(data-title) ": ";
					float: left;
					font-weight: 600;
					padding-right: 1em;
				}

				&.product-remove {
					&:before {
						display: none;
					}
				}

				&.actions:before,
				&.download-actions:before {
					display: none;
				}

				&.download-actions .button {
					display: block;
					text-align: center;
				}
			}

			@include media(">=m") {
				thead {
					display: table-header-group;
				}
				tbody {
					tr td,
					tr th {
						text-align: left;
					}

					th {
						display: table-cell;
					}
				}
				tr td {
					display: table-cell;

					&:before {
						display: none
					}
				}
			}

			/**
			 * Account Orders
			 */
			&.my_account_orders {
				.order-actions {
					text-align: right;

					&:before {
						display: none;
					}
				}
			}
		}

		/**
         * Attributes
         */
		&.shop_attributes {
			tbody {
				display: block;
			}

			tr {
				display: flex;
			}

			th {
				flex: 1 0 auto;
				width: 30%;
			}

			td {
				width: 70%;
			}
		}

		/**
		 * Cart
		 */
		&.cart {
			margin-bottom: 4rem;

			.product-quantity {
				.minus,
				.plus {
					display: none;
				}

				@include media(">=m") {
					.minus,
					.plus {
						display: inline-block;
					}
				}
			}

			.product-thumbnail {
				@include media(">=m") {
					display: table-cell;
				}

				img {
					height: auto;
					margin: 0 auto;
					max-width: 3.706325903em;
				}

				&:before {
					display: none;
				}
			}

			td,
			th {
				padding: .875em .875em 0;
				@include media(">=m") {
					padding: 1.618em;
				}
			}

			thead {
				@include media(">=m") {
					font-size: 1em;
					text-indent: 0
				}
			}

			.qty {
				@include media(">=m") {
					max-width: 3.632em;
					padding: .875em;
				}
			}

			.quantity .qty {
				@include media(">=m") {
					padding: .6180469716em
				}
			}

			tr:first-child td.product-remove {
				border-top-width: 0;
			}

			td.product-remove {
				@include clearfix();
				border-top: 1.618em solid #fff;
				padding: 0;
				position: relative;

				a.remove {
					color: red;
					font-size: 1.5em;
					left: 0.875em;
					position: absolute;
					top: 0.875em;
				}

				@include media(">=m") {
					border: 0;
					display: table-cell;
					padding: 1.618em;
					a.remove {
						float: none;
						left: auto;
						position: relative;
						top: auto;
					}
				}
			}

			td.product-quantity {
				.qty {
					padding: .326em;
					width: 3.706325903em;
				}
			}

			td.product-subtotal {
				padding-bottom: .875em;
			}

			td.actions {
				background-color: transparent;
				border-top: 1em solid #fff;
				padding: 0;

				label {
					display: none;
				}

				.coupon {
					border-bottom: 1px solid rgba(0, 0, 0, .05);
					margin-bottom: 1em;
					padding-bottom: 1em;
				}

				input {
					display: block;
					margin: .5407911001em 0;
					width: 100%;

					&[name=update_cart]:focus {
						outline: 0
					}
				}

				@include media(">=m") {
					text-align: right;
					label {
						display: none;
					}
					.coupon {
						border-bottom: none;
						display: inline-block;
						float: left;
						margin-bottom: 0;
						padding-bottom: 0;
					}
					input {
						display: inline-block;
						margin: 0;
						width: auto;
					}
				}
			}

			td.product-price,
			td.product-subtotal {
				.amount {
					@include typography($h3);
					display: block;
					font-weight: fontweight-get(bold);

				}

				del .amount {
					color: color(text, light);
					font-size: 18px;
					font-weight: fontweight-get(regular);
					opacity: 0.5;
					order: 1;
				}

				ins .amount {
					color: color(secondary, base);
				}
			}
		}

		& + .addresses {
			@include media(">=m") {
				margin-top: 1.5rem;
			}
		}
	}
}