////
/// ============================================================================
/// WOOCOMMERCE ACCOUNT
/// ============================================================================
///
/// @group woocommerce
///

/**
 * Navigation
 */
.woocommerce-MyAccount-navigation {
	@include media(">=m") {
		@include span(3);
	}

	ul {
		list-style: none;
		margin-bottom: 0;
		margin-top: 0;
		padding-left: 0;

		li {
			border-bottom: 1px solid color(gray-light);
			border-bottom: 1px solid rgba(0, 0, 0, .05);
			list-style: none;
			margin-left: 0;
			position: relative;

			a {
				align-items: center;
				color: color(text, base);
				cursor: pointer;
				display: flex;
				flex-direction: row-reverse;
				justify-content: space-between;
				padding: 0.5rem 1em;
				position: relative;

				&:hover {
					color: color(primary, base);

					&:before {
						opacity: 1;
					}
				}
			}

			&.is-active {
				a {
					color: color(primary, base);

					&:before {
						opacity: 1;
					}
				}
			}

			// Icons
			a:before {
				@extend %woocommerce-icon;
				float: right;
				font-size: 1.2em;
				margin-left: 0.5em;
				opacity: .25;
			}

			&.woocommerce-MyAccount-navigation-link {
				&--dashboard a:before {
					content: "\e902";
				}

				&--orders a:before {
					content: "\e90b";
				}

				&--subscriptions a:before {
					content: "\e910";
				}

				&--downloads a:before {
					content: "\e904";
				}

				&--edit-address a:before {
					content: "\e908";
				}

				&--payment-methods a:before {
					content: "\e90e";
				}

				&--edit-account a:before {
					content: "\e90a";
				}

				&--invoice a:before {
					content: "\e913";
				}

				&--customer-logout a:before {
					content: "\e90f";
				}

				&--wc-smart-coupons a:before {
					content: "\e912";
				}

				&--affiliate a:before {
					content: "\e914";
				}

				&--certificate a:before {
					content: "\e907";
				}

				&--acropoli a:before {
					content: "\e915";
				}
			}
		}
	}
}


/**
 * Content
 */
.woocommerce-MyAccount-content {
	@include media(">=m") {
		@include span(last 9);
	}

	h2, h3 {
		margin-bottom: 1rem;
		margin-top: 3rem;

		&:first-of-type {
			margin-top: 0;
		}
	}

	> p {
		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	.col2-set {
		@include media(">=m") {
			.col-1 {
				@include span(4.5 of 9);
			}
			.col-2 {
				@include span(last 4.5 of 9);
			}
		}
	}

	.form-row-first {
		@include media(">=m") {
			@include span(4.5 of 9);
		}
	}

	.form-row-last {
		@include media(">=m") {
			@include span(last 4.5 of 9);
		}
	}

	table tbody {
		.button {
			font-size: 1rem;
			padding: 0.2em 1em;
			text-transform: uppercase;
		}
	}

	#payment {
		.payment_methods {
			margin-bottom: 2rem !important;

			li {
				.payment_box {
					background-color: rgba(#000, .05);
				}
			}

			.payment_method_stripe {
				padding: 1rem;

				.payment_box {
					background: transparent;
					padding: 2rem 0 0 0;
				}
			}
		}
	}
}


/**
 * Adresses
 */
.addresses {
	header.title {
		@include clearfix();

		h3 {
			float: left;
		}

		a {
			float: right;
			position: relative;

			&:before {
				@extend %woocommerce-icon;
				content: "\e905";
				display: none;
			}

			@include media(">=m") {
				display: block;
				height: 1em;
				margin-top: 0.5em;
				padding: .875em;
				text-indent: -9999px;
				width: 1em;
				&:before {
					@include absolute(top 0 left 0);
					display: inline-block;
					height: 100%;
					line-height: 1.618;
					text-align: center;
					text-indent: 0;
					width: 100%;
				}
			}
		}
	}
}


/**
 * Order Details (Scontrino)
 */
ul.order_details {
	@include clearfix;
	background-color: #f8f8f8;
	list-style: none;
	margin: 4rem 0;
	padding-left: 0;
	position: relative;

	&:before,
	&:after {
		@include absolute(top -16px left 0);
		background: -webkit-linear-gradient(transparent 0, transparent 0), -webkit-linear-gradient(135deg, #f8f8f8 33.33%, transparent 33.33%), -webkit-linear-gradient(45deg, #f8f8f8 33.33%, transparent 33.33%);
		background-size: 0 100%, 16px 16px, 16px 16px;
		content: "";
		display: block;
		height: 16px;
		width: 100%;
	}

	&:after {
		bottom: -16px;
		top: auto;
		transform: rotate(180deg);
	}

	& > li {
		border-bottom: 1px dotted #e3e3e3;
	}

	li {
		font-size: 0.85rem;
		padding: 1em 2rem;
		text-transform: uppercase;

		&:first-child {
			padding-top: 1.5rem;
		}

		&:last-child {
			border: 0;
			padding-bottom: 1.5rem;
		}

		strong {
			display: block;
			font-size: 1rem;
			text-transform: none;
		}
	}
}


/**
 * Acropoli
 */
.c-acropoli-account-list {
	list-style: none;
	padding-left: 0;

	&__item {
		align-items: center;
		border-bottom: 1px solid color(gray-light);
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 15px;
		padding-bottom: 15px;

		&:last-of-type {
			border-bottom: 0;
		}
	}

	&__image {
		margin-right: 1em;
		width: 70px;
	}

	&__title {
		flex: 1 1 auto;
		margin-right: 1em;
	}

	&__view {

	}

	&__download {
		margin-left: 1em;
	}
}
