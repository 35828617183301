////
/// ============================================================================
/// WOOCOMMERCE ICONS
/// ============================================================================
///
/// @group woocommerce
///

@font-face {
	font-family: 'woocommerce';
	src:  url('fonts/woocommerce.eot');
	src:  url('fonts/woocommerce.eot#iefix') format('embedded-opentype'),
	url('fonts/woocommerce.ttf') format('truetype'),
	url('fonts/woocommerce.woff') format('woff'),
	url('fonts/woocommerce.svg#woocommerce') format('svg');
	font-weight: normal;
	font-style: normal;
}

%woocommerce-icon {
	font-family: 'woocommerce';
	display: inline-block;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	line-height: 1;
	text-transform: none;
	speak: none;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@keyframes wc-icon-spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(359deg);
	}
}