////
/// ============================================================================
/// SINGLE
///
/// 01. Layout
/// 02. Author Box
/// 03. Comments
/// 04. Respond
/// ============================================================================
///
/// @group pages
////

/**
 * Layout
 */
.single-post {
	&__title {
		@include media(">=m") {
			padding-bottom: 10rem;
		}
	}

	&__body {
		/* Reset */
		p, ul, ol {
			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		h1 {
			@include typography($h2);
		}

		h1, h2 {
			strong {
				font-weight: inherit;
			}
		}

		h1, h2, h3, h4, h5, h6,
		p, ul, ol {
			&[style] {
				color: inherit !important;
				font-family: inherit !important;
				text-align: left !important;
			}

			&[align='justify'] {
				text-align: left;
			}
		}

		h1::before, h2::before, h3::before, h4::before, h5::before, h6::before {
			content: " ";
			display: block;
			height: 60px;
			margin-top: -60px;
			visibility: hidden;
		}

		/* Table */
		table {
			border: 0;
			border-collapse: collapse;
			border-spacing: 0;
			margin-bottom: 1rem;
			width: 100%;

			thead {
				background: color(gray);
				color: #fff;
				display: none;
			}

			tr {
				border-bottom: 2px solid color(gray);
				display: block;

				&.even,
				&.alt,
				&:nth-of-type(even) {
					background: color(gray-lighter);
				}
			}

			th {
				font-weight: bold;
				padding: 0.5rem;
				text-transform: uppercase;
			}

			td {
				border-bottom: 1px dotted color(gray-light);
				display: block;
				padding: 0.5rem;
				text-align: right;
				width: auto;

				&:last-child {
					border-bottom: 0;
				}

				&:before {
					content: attr(data-label);
					float: left;
					font-weight: bold;
					text-transform: uppercase;
				}
			}

			&[style] {
				border-collapse: collapse !important;
				width: 100% !important;
			}

			tr[style] {
				height: inherit !important;
				width: inherit !important;
			}

			th[style],
			td[style] {
				height: inherit !important;
				text-align: right !important;
				width: inherit !important;
				@include media(">=m") {
					text-align: left !important;
				}
			}

			@include media(">=m") {
				border: 1px solid color(gray-light);
				thead {
					display: table-header-group;
				}
				tr {
					border-bottom: 0 none;
					display: table-row;
					margin-bottom: 0;
				}
				td {
					&, &:last-child {
						border-bottom: 1px dotted color(gray-light);
					}

					&:before {
						display: none;
					}
				}
				tbody {
					tr:last-child td {
						border-bottom: 0 none;
					}
				}
				th, td {
					display: table-cell;
					text-align: left;
					vertical-align: top;
				}
			}
		}
	}

	.archive-list--book .entry {
		margin-right: 0;
		width: 100%;
	}
}


/**
 * Author Box
 */
.author-box {
	@include clearfix;
	background: color(gray-lighter);
	padding: 2rem;

	&__avatar {
		margin-bottom: 2rem;
		@include media(">=s") {
			float: left;
			margin-bottom: 0;
		}

		img {
			border: 2px solid #fff;
			border-radius: 50%;
		}
	}

	&__info {
		@include media(">=s") {
			margin-left: 100px;
			padding-left: 1em;
		}
	}

	&__social {
		ul {
			@include reset(list);
			display: inline-block;
		}

		li {
			display: inline-block;
			padding-left: 0.5em;
		}

		a {
			text-decoration: none;
		}
	}
}


/**
 * Comments
 */
.comments {
	&__header {
	}

	&__wrap {
		.tabs__label {
			width: 50%;
			@include media(">=m") {
				width: (100% / 3);
			}
		}
	}

	&__list {
		@include reset(list);

		&--child {
			border-left: 4px solid color(gray-lighter);
			margin-left: 2em;

			li {
				padding-left: 1em;
			}
		}
	}

	&__nav {
	}

	&__disable {
		margin-top: 2rem;
	}
}

.comment {
	padding-bottom: 4rem;
	@include media(">=l") {
		padding-bottom: 0;
	}

	&__wrap {
		margin-bottom: 2rem;
		margin-top: 2rem;
	}

	&__content {
		word-break: break-word;
	}

	&__meta {
		margin-bottom: 1rem;
		padding-left: 70px;
		position: relative;

		.meta__name {
			color: color(gray-darker);
			display: block;
			font-style: normal;
			font-weight: fontweight-get(bold);
		}

		.meta__date {
			color: color(gray);
			font-size: 0.8rem;
			text-transform: uppercase;
		}
	}

	&-reply-link {
		@include media(">=s") {
			@include absolute(top right, 10);
		}
	}
}


/**
 * Respond
 */
.respond {
	&__terms {
		@include clearfix();
	}

	&__alert {
		margin-bottom: 1rem;
	}
}


/**
 * Toc
 */
.c-toc {
	&__title {
		margin-bottom: 0.5rem;
	}

	&__nav {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-left: -1em;
		margin-right: -1em;
	}

	&__item {
		border-bottom: color(gray-light) 1px solid;
		color: color(primary, base);
		display: flex;
		flex: 1 1 auto;
		line-height: 1.25;
		margin: 0 1em;
		padding: 0.5em 0;
		width: 100%;

		&::before {
			color: color(text, base);
			content: "• ";
			display: block;
			flex: 0;
			padding-right: 10px;
			text-indent: 0;
		}

		@include media(">=s") {
			max-width: calc(50% - 2em);
		}
	}
}
