////
/// ============================================================================
/// WOOCOMMERCE FORM
/// ============================================================================
///
/// @group woocommerce
///

div.woocommerce {
	form {
		@include clearfix();
		margin-bottom: 2rem;
	}

	//button, input, select, textarea {
	//	border-radius: 0;
	//	font-size: 100%;
	//	margin: 0;
	//	vertical-align: baseline;
	//	width: auto;
	//}

	//input[type=checkbox],
	//input[type=radio] {
	//	padding: 0;
	//}
    //
	//input[type=search]::-webkit-search-decoration {
	//	-webkit-appearance: none;
	//}
    //
	//input[type=search] {
	//	box-sizing: border-box;
	//}
    //
	//button::-moz-focus-inner,
	//input::-moz-focus-inner {
	//	border: 0;
	//	padding: 0;
	//}

	//.input-text,
	//input[type=email],
	//input[type=password],
	//input[type=search],
	//input[type=text],
	//input[type=url],
	//textarea {
	//	-webkit-appearance: none;
	//	-moz-appearance: none;
	//	background-color: #fff;
	//	border: 1px solid color(gray-light);
	//	border-radius: $border-radius;
	//	box-shadow: inset 0 1px 2px rgba(#000, 0.1);
	//	color: color(text, base);
	//	display: block;
	//	font-family: inherit;
	//	font-size: 1rem;
	//	outline: 0;
	//	padding: 0.7rem 1em;
	//	transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
    //
	//	&:focus {
	//		background-color: #fff;
	//		border: 1px solid darken(color(gray-lighter), 10%);
	//		box-shadow: 0 0 5px rgba(#000, 0.1);
	//		outline: none;
	//		transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
	//	}
	//}
    //
	//textarea {
	//	overflow: auto;
	//	padding-left: .875em;
	//	vertical-align: top;
	//	width: 100%;
	//}

	label {
		&.inline input {
			width: auto;
		}

		&.radio {
			display: inline;
			margin-right: 20px;
		}
	}

	fieldset {
		border: 0;
		padding: 1.5rem 0 0 0;
		margin: 1.5rem 0;
		legend {
			font-weight: fontweight-get(bold);
		}
	}

	[placeholder]:focus::-webkit-input-placeholder {
		transition: opacity .5s .5s ease;
		opacity: 0;
	}

	.required {
		border-bottom: 0 !important;
		color: red;
		display: inline-block; // Reset theme style
		font-size: inherit; // Reset theme style
		position: static; // Reset theme style
	}
}


/**
 * General WooCommerce components
 */
.woocommerce-form__label-for-checkbox {
	cursor: pointer;
	display: block;

	.woocommerce-form__input-checkbox {
		margin-right: 0.5em;
	}
}

.woocommerce {
	.form-row {
		margin-top: 0;
		label {
			display: block;
		}

		input,
		select,
		textarea {
			width: 100%;
		}

		input[type=checkbox],
		input[type=radio],
		.button {
			width: auto;
		}

		&.create-account label {
			display: inline-block;
		}

		&.woocommerce-validated input.input-text {
			box-shadow: inset 2px 0 0 color(success);
		}

		&.woocommerce-invalid input.input-text {
			box-shadow: inset 2px 0 0 color(error);
		}

		&-first {
			@include span(6);
			clear: both;
		}

		&-last {
			@include span(last 6);
		}
		&-wide {
			clear: both;
			width: 100%;
		}
	}
}


/**
 * Arrow
 */
.wc-forward:after,
.woocommerce-Button--next:after {
	@extend %woocommerce-icon;
	content: "\e900";
	margin-left: 0.5em;
}


/**
 * Custom Select2
 */
.select2-container--default {
	.select2-selection--single {
		border: 1px solid color(gray-light);
		height: 40px;

		.select2-selection__rendered {
			color: color(text, base);
			font-size: 1rem;
			line-height: 40px;
			padding: 0 1em;
		}

		.select2-selection__arrow {
			margin-top: -13px;
			top: 50%;
		}
	}
	.select2-results>.select2-results__options {
		font-size: 1rem;
	}
}

/**
 * ARG Multistep Checkout for WooCommerce
 */
.woocommerce-checkout .woocommerce .argmc-wrapper label.radio {
	display: inline;
	margin-right: 20px;
}
