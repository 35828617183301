////
/// ============================================================================
/// ALERT
///
/// 01. Alert
/// 02. Alert Success
/// 03. Alert Info
/// 04. Alert Warning
/// 05. Alert Error
/// ============================================================================
///
/// @group components
////

.alert {
    background: color(gray-light);
    color: color(text, base);
    border-left: 0.5em solid rgba(#000, 0.15);
    padding: 1rem 1.5em;
    position: relative;
    
    $alerts: success, info, warning, error;
    
    @each $alert in $alerts {
        $background: color($alert);

        &--#{$alert} {
            background: $background;
            color: #fff;
        }
    }
    
    > :first-child {
        margin-top: 0;
    }
    > :last-child {
        margin-bottom: 0;
    }
}
