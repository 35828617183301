////
/// ============================================================================
/// MAILCHIMP
///
/// Form Embed Code - Classic - 12/17/2015 v10.7
/// ============================================================================
///
/// @group vendors
////

.mailchimp {
    &__group {
        @include clearfix;

        @include media(">=l") {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .mc-name {
                margin-right: 2%;
                flex: 1 1 33%;
            }
            .mc-email {
                margin-right: 2%;
                flex: 1 1 33%;
            }
            .mc-gdpr {
                order: 1;
            }
            .mc-help {
                color: #ccc;
                font-size: 15px;
                line-height: 1.2;
                order: 2;
                a {
                    color: #ccc;
                }
            }
            .mc-submit {
                flex: 1 1 30%;
            }
        }
    }

    &__field {
        margin-bottom: 1rem;
        position: relative;
        width: 100%;

        .error-message {
            color: red;
            display: inline-block;
            font-size: 14px;
            font-weight: 400;
            line-height: 1;
            margin: 0;
            padding: 5px 0;
            width: 100%;
        }

        label {
            color: #fff;
        }

        input[type="checkbox"] {
            position: absolute;
            opacity: 0;
            + span {
                &:before {
                    content: '';
                    background: transparent;
                    border-radius: $border-radius;
                    border: 1px solid color(gray-light);
                    display: inline-block;
                    width: 1em;
                    height: 1em;
                    position: relative;
                    top: 0.3em;
                    margin-right: .5em;
                    vertical-align: top;
                    cursor: pointer;
                    text-align: center;
                    transition: all 250ms ease;
                }
            }
            &:checked {
                + span {
                    &:before {
                        background-color: color(primary, base);
                        box-shadow: inset 0 0 0 3px #f4f4f4;
                    }
                }
            }
            &:focus {
                + span {
                    &:before {
                        outline: none;
                        border-color: color(primary, base);
                    }
                }
            }
            &:disabled {
                + span {
                    &:before {
                        box-shadow: inset 0 0 0 3px #f4f4f4;
                        border-color: lighten(color(gray-lighter), 8%);
                        background: lighten(color(gray-lighter), 8%);
                    }
                }
            }
            + span {
                &:empty {
                    &:before {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    &__input {
        background: transparent;
        color: #fff;
        font-size: inherit;
        position: relative;
        text-transform: inherit;
        top: 0;
        &::placeholder {
            color: rgba(#fff, 0.5);
        }
        &:focus {
            background: transparent;
        }
        &.error {
            border-color: color(error);
        }
    }

    &__status {
        @include clearfix;
        &:focus {
            outline: none;
        }

        &.error-message {
            @extend .alert;
            @extend .alert--error;
            margin-top: 1rem;
        }
        &.success-message {
            @extend .alert;
            @extend .alert--success;
            margin-top: 1rem;
        }
    }
}