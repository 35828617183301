////
/// ============================================================================
/// SITEMAP
/// ============================================================================
///
/// @group components
////

.wsp-container {
    > .wsp-posts-list {
        > li {
            margin-bottom: 1rem;
        }
    }
}

.wsp-pages-title,
.wsp-posts-title {
    border-bottom: 1px solid color(gray-light);
    padding-bottom: 1rem;
}

.wsp-posts-title {
    margin-top: 2rem;
    &:first-child {
        margin-top: 0;
    }
}

.wsp-pages-list,
.wsp-posts-list {
    margin: 0;
    a {
        color: color(text, base);
        text-decoration: none;
        &:hover {
            color: color(primary, base);
            text-decoration: underline;
        }
    }
}