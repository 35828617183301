////
/// ============================================================================
/// WOOCOMMERCE DEFAULT
/// ============================================================================
///
/// @group woocommerce
///

div.woocommerce {
	@include clearfix();
	width: 100%; // Fix for display flex

	mark,
	ins {
		background: transparent;
		font-weight: 600;
		text-decoration: none;
	}

	label.inline {
		display: inline;
		margin-left: 1em;

		input {
			width: auto;
		}
	}

	.clear {
		@include clearfix();
	}

	.quantity label {
		@include hide(visuallyhidden);
	}
}


.u-columns {
	@include clearfix();
}


.blockUI {
	position: relative;

	&:after {
		@include center(center, 40px, 40px);
		@extend %woocommerce-icon;
		animation: wc-icon-spin 0.75s linear infinite;
		content: "\e90c";
		font-size: 40px;
		line-height: 40px;
	}
}


.col2-set {
	@include media(">=m") {
		@include span(6);

		.form-row-first {
			@include span(3 of 6);
		}

		.form-row-last {
			@include span(last 3 of 6);
		}

		.col-1,
		.col-2 {
			margin-bottom: 2rem;
		}

		& + #customer_details {
			display: none;
		}

		.woocommerce-billing-fields {
			@include clearfix;
		}

		&#customer_login,
		&.addresses {
			@include span(full);

			.col-1 {
				@include span(6);
			}

			.col-2 {
				@include span(last 6);
			}
		}
	}
}


/**
 * Star Rating
 */
.star-rating {
	@extend %woocommerce-icon;
	font-size: 1em;
	height: 1em;
	line-height: 1;
	overflow: hidden;
	position: relative;
	width: 5em;

	&:before {
		@include absolute(top 0 left 0);
		content: "\e909\e909\e909\e909\e909";
		float: left;
		opacity: 0.25;
	}

	span {
		@include absolute(top 0 left 0);
		float: left;
		overflow: hidden;
		padding-top: 1em;

		&:before {
			@include absolute(top 0 left 0);
			color: #fc0;
			content: "\e909\e909\e909\e909\e909";
		}
	}
}

p.stars {
	display: inline-block;
	margin: 0;

	a {
		display: inline-block;
		height: 1em;
		margin-right: 1px;
		position: relative;
		text-decoration: none;
		text-indent: -999em;
		width: 1em;

		&:before {
			@extend %woocommerce-icon;
			@include absolute(top 0 left 0);
			color: color(text, base);
			content: "\e909";
			display: block;
			height: 1em;
			line-height: 1;
			opacity: 0.25;
			text-indent: 0;
			width: 1em;
		}

		&:hover {
			~ a:before {
				color: color(text, base);
				content: "\e909";
				opacity: 0.25;
			}
		}
	}

	&:hover {
		a {
			&:before {
				color: #fc0;
				content: "\e909";
				opacity: 1;
			}
		}
	}

	&.selected {
		a.active {
			&:before {
				color: #fc0;
				content: "\e909";
				opacity: 1;
			}

			~ a:before {
				color: color(text, base);
				content: "\e909";
				opacity: .25;
			}
		}

		a:not(.active) {
			&:before {
				color: #fc0;
				content: "\e909";
				opacity: 1;
			}
		}
	}
}

.woocommerce-product-rating {
	align-items: center;
	display: flex;
	justify-content: center;

	.star-rating {
		margin-right: 1em;
		margin-top: -3px;
	}
}


/**
 * Offer Badge
 */
.onsale {
	$size: 80px;

	@include absolute(bottom 0 right 0, 10);
	background: transparent;
	color: #fff;
	font-size: 10px;
	font-weight: fontweight-get(bold);
	height: $size;
	line-height: ($size / 1.8);
	text-align: center;
	text-transform: uppercase;
	width: $size;

	@include media(">=m") {
		font-size: 12px;
	}

	&:after {
		@include absolute(top 0 right 0);
		border-bottom-color: color(success);
		border-color: transparent;
		border-right-color: color(success);
		border-style: solid;
		border-width: ($size / 2);
		content: '';
	}

	span {
		@include absolute(top 50% left 50%, 1);
		display: block;
		line-height: 12px;
		padding-top: 24px;
		transform: translate3d(-50%, -50%, 0) rotate(-45deg);
		transform-origin: 50% 50%;
		width: 90%;
	}
}


/**
 * Price
 */
.price {
	@include typography($h3);
	align-items: center;
	display: flex;
	font-weight: fontweight-get(bold);
	justify-content: center;
	margin-bottom: vr(1, $map: $h3);
	margin-top: 0;
	width: 100%;

	del {
		font-size: 18px;
		font-weight: fontweight-get(regular);
		opacity: 0.5;
		order: 1;

		& + ins {
			margin-right: 0.5em;
		}
	}

	ins {
		text-decoration: none;
	}
}


/**
 * Remove
 */
a.remove {
	border: 1px solid color(gray-light);
	border-radius: 1.5em;
	display: block;
	height: 1.5em;
	line-height: 1.5;
	position: relative;
	text-align: center;
	width: 1.5em;
}