////
/// ============================================================================
/// BUTTONS
/// ============================================================================
///
/// @link http://unicorn-ui.com/buttons/ Ispiration
///
/// @group components
////

.btn {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #fff;
    border: 1px solid color(gray-light);
    border-radius: $border-radius;
    box-shadow: none;
    color: color(gray);
    cursor: pointer;
    display: inline-block;
    font-family: inherit;
    font-size: 1rem;
    font-weight: fontweight-get(bold);
    line-height: normal;
    margin: 0;
    max-width: 100%;
    outline: 0;
    overflow: hidden;
    padding: 0.7rem 2em;
    position: relative;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    text-overflow: ellipsis;
    transition: all 0.25s ease-out;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;

    &:hover,
    &:focus {
        background: color(primary, dark);
        border-color: color(primary, dark);
        color: #fff;
        outline: none;
        text-decoration: none;
    }

    &--primary {
        background: color(primary, base);
        border-color: color(primary, base);
        color: #fff;
    }

    &--ghost {
        background: transparent;
        color: color(primary, base);
    }

    &--negative {
        background: transparent;
        border-color: #fff;
        color: #fff;
        &:hover,
        &:focus {
            background: transparent;
            border-color: color(primary, base);
            color: color(primary, base);
        }
    }

    &--expand {
        display: block;
        width: 100%;
    }

    &--center {
        left: 50%;
        position: relative;
        transform: translateX(-50%);
    }

    /* Icon */
    svg {
        @include transform(y);
        display: none;
        height: 1.5rem;
        margin-left: 0.5em;
        margin-top: .0625em; // Fix svg position
        width: 1.5rem;
    }
    &--icon {
        padding-right: 3.5em; // Button Padding Side + Svg Size
        svg {
            display: inline-block;
        }
    }
}