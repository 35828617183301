.small_width.bundle_form table.bundled_products tr td.bundled_item_images_col,
.small_width.composite_form .bundle_form table.bundled_products tr td.bundled_item_images_col {
	width: 20%;
}

.small_width.bundle_form table.bundled_products td,
.small_width.composite_form .bundle_form table.bundled_products td {
	display: table-cell;
	vertical-align: middle;
}

table.bundled_products td.bundled_item_col {
	padding: 0.5em;
}

.small_width.bundle_form table.bundled_products tr td.bundled_item_images_col,
.small_width.bundle_form table.bundled_products tr td.bundled_item_details_col,
.small_width.composite_form .bundle_form table.bundled_products tr td.bundled_item_images_col,
.small_width.composite_form .bundle_form table.bundled_products tr td.bundled_item_details_col {
	padding-bottom: 0.5em;
}