////
/// ============================================================================
/// FILTERS
/// ============================================================================
///
/// @group components
////

/**
 * Form
 */
.c-filters-form {
    display: flex;
    flex-wrap: wrap;
    margin: 0 calc(1rem * -1);
    &__group {
        display: flex;
        flex-grow: 1;
        flex-basis: calc(480px * 999 - 100vw * 999);
        flex-direction: column;
        min-width: calc(#{(100% / 2)} - #{(1rem * 2)});
        max-width: 100%;
        margin: 0.5rem 1rem;
        @include media(">=m") {
            min-width: calc(#{(100% / 4)} - #{(1rem * 2)});
        }
        @include media(">=l") {
            min-width: calc(#{(100% / 5)} - #{(1rem * 2)});
        }

        &.-actions {
            justify-content: flex-end;
        }
    }
    &__label {
        @include typography($h6);
        margin-bottom: 0.5em;
    }
    &__field {
        &.-checkbox {
            display: flex;
            align-items: center;
            input {
                margin-right: 0.2em;
            }
        }
    }
}

/**
 * Cards
 */
.c-card-list {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: calc(1rem * -1);
    padding: 0;
}

.c-card {
    background-color: transparent;
    border-radius: $border-radius;
    box-shadow: 0 0 0 1px color(gray-light);
    display: flex;
    flex-basis: calc(480px * 999 - 100vw * 999);
    flex-direction: column;
    justify-content: space-between;
    min-width: calc(#{(100% / 2)} - #{(1rem * 2)});
    margin: 1rem;
    max-width: 100%;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 100%;

    @include media(">=m") {
        min-width: calc(#{(100% / 3)} - #{(1rem * 2)});
    }
    @include media(">=l") {
        min-width: calc(#{(100% / 4)} - #{(1rem * 2)});
    }
    @include media(">=xl") {
        min-width: calc(#{(100% / 5)} - #{(1rem * 2)});
    }

    &__link {
        @include absolute(top 0 left 0, 1);
        height: 100%;
        width: 100%;
        &:hover {
            & + div h4 {
                color: color(primary, base);
            }
        }
    }

    &__header {
        padding: 1rem 1rem 0;
        position: relative;
    }
    &__media {
        position: relative;
    }
    &__body {
        flex: 1;
        padding: 1rem;
        & > *:first-child {
            margin-top: 0;
        }
        & > *:last-child {
            margin-bottom: 0;
        }

        p {
            color: color(text, light);
            font-size: 0.85em;
            margin: 0;
            max-height: 5rem;
            position: relative;
            overflow: hidden;
            &::after {
                @include absolute(bottom 0 left 0);
                background: linear-gradient(to top, #fff, ease-in-out, transparent); // @see https://css-tricks.com/easing-linear-gradients/
                content: '';
                display: block;
                height: 3rem;
                width: 100%;
            }
        }
    }

    .c-avatar {
        margin-bottom: 0.5rem;
    }
}

.c-heading {
    &__title {
        line-height: 1;
    }
    &__sub {
        font-size: 0.75em;
        opacity: 0.6;
    }
    & > span {
        display: block;
    }
    & > *:nth-child(2n) {
        margin-top: 0.2em;
    }
}

.c-title {
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    &__media {
        flex: 0 0 auto;
        margin-right: 1em;
    }
    &__text {
        flex: 1;
        margin: 0;
    }
}