////
/// ============================================================================
/// SINGULAR
///
/// 01. Layout
/// 02. Related
/// 03. Image Caption
/// ============================================================================
///
/// @group pages
////

/**
 * Layout
 */
.single {
    &__header {}
    &__title {}
    &__image {
        text-align: center;
        @include media(">=m") {
            @include squish(1, 1);
            margin-top: -10rem;
            padding-top: 0;
        }
    }
    &__body {
        width: 100%;
        .l-main__left & {
            @include media(">=m") {
                float: right;
                width: span(6.5 of 8.5);
            }
        }
        img {
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }
    &__thecontent {
        @include clearfix();
    }
    &__content {
        @include clearfix();
        &-wrap {
            @include clearfix();
            width: 100%;
        }
    }
    &__share {
        &--top {
            display: none;
            @include media(">=m") {
                @include span(2 of 8.5);
                display: block;
            }
            .share--icon {
                .social {
                    flex-direction: column;
                    &__item {
                        margin: auto;
                        &--whatsapp {
                            display: none;
                        }
                    }
                }
            }
        }
        &--sticky {
            @include fixed(bottom 0 left 0, 100);
            height: 40px;
            transform: translateY(100px);
            transition: transform 0.5s;
            width: 100%;
            @include media(">=m") {
                display: none;
            }
            &.is-show {
                transform: translateY(0);
            }
            .share--icon {
                height: 100%;
                .social {
                    height: 100%;
                    &__item {
                        height: 100%;
                        width: (100% / 4);
                    }
                }
            }
        }
    }
    &__wrap {
    }
    &__meta {
        opacity: 0.7;
        a {
            color: #fff;
        }
    }
    &__body {}
}


/**
 * Related
*/
.related {
    overflow: hidden;
    position: relative;

    &__header {}
    &__content {}

    .swiper-pagination-bullets {
        margin-top: 2rem;
        @include media(">=m") {
            @include center(right, 30px, auto);
            margin-top: 0;
            text-align: right;
        }
    }

    .entry {
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 0;

        width: span(6);

        @include media(">=m") {
            width: span(4);
        }
        @include media(">=l") {
            width: span(3);
        }

        &__image,
        &__content {
            width: 100%;
        }

        &-post {
            &__image {
                margin-bottom: 1rem;
            }
        }
    }
}


/**
 * Image Caption
 */
.caption {
    margin-bottom: vr(1);
    margin-top: vr(1);
    img {}
    &__text {
        color: color(text, light);
        padding: vr(0.5) 2em 0 2em;
        text-align: center;
    }
}


/**
 * Banner
 */
.c-banner {
    @include clearfix;
    list-style: none;
    margin: vr(2) 0;
    padding: 0;
    &__item {
        text-align: center;
        &.-mobile {
            display: block;
            @include media(">=l") {
                display: none;
            }
        }
        &.-desktop {
            display: none;
            @include media(">=l") {
                display: block;
            }
        }
    }
    br {
        display: none;
    }
}