////
/// ============================================================================
/// VARIABLES
/// ============================================================================
///
/// @group config
////

/**
 * Debug
 */
$debug: false;


/// Font Family
///
/// @prop {Type} body       ["Arial, Helvetica, sans-serif"]                        - Body Text
/// @prop {Type} heading    ["'Open Sans', Arial, Helvetica, sans-serif"]           - Heading h1, h2, etc
/// @prop {Type} mono       ["Menlo, Monaco, Consolas, 'Courier New', monospace"]   - Code
///
/// @example scss - Usage
/// .foo {
///     font-family: fontfamily-get(heading);
/// }
///
/// @example css - CSS Output
/// .foo {
///     font-family: 'Open Sans', Arial, Helvetica, sans-serif;
/// }
///
/// @type Map
$fontfamily: (
		body : "'PT Sans', Helvetica, Arial, sans-serif",
		heading : "'Roboto Condensed', Helvetica, Arial, sans-serif",
		mono : "Menlo, Monaco, Consolas, 'Courier New', monospace"
);


/// Font Weight
///
/// @prop {Type} thin       100
/// @prop {Type} light      300
/// @prop {Type} normal     400
/// @prop {Type} bold       700
/// @prop {Type} heavy      900
///
/// @example scss - Usage
/// .foo {
///     font-weight: fontweight-get(regular);
/// }
///
/// @example css - CSS Output
/// .foo {
///     font-weight: 400;
/// }
///
/// @type Map
$fontweight: (
		thin : null,
		light : null,
		regular : 400,
		bold : 700,
		heavy : null
);


/**
 * Typography
 *
 * @link http://www.gridlover.net/try
 * @link http://pxtoem.com/
 *
 */
$typography: (
		null : (16px, 1.6),
		s : (16px, 1.6),
		m : (16px, 1.6),
		l : (18px, 1.65),
		xl : (18px, 1.65)
);

/* Heading */
$h1: (
		null : (48px, 1),
		m : (56px, 1),
		l : (72px, 1)
);

$h2: (
		null : (36px, 1.44),
		m : (42px, 1.23),
		l : (48px, 1.25)
);

$h3: (
		null : (26px, 1.2),
		m : (28px, 1.2),
		l : (32px, 1.2)
);

$h4: (
		null : (18px, 1.529),
		m : (20px, 1.368),
		l : (21px, 1.363)
);

$h5: (
		null : (16px, 1.3),
		l : (18px, 1.3)
);

$h6: (
		null : (16px, 1.625),
		l : (18px, 1.666)
);

$m: (
		null : (20px, 1.2),
		m : (22px, 1.2),
		l : (24px, 1.2)
);


/**
 * Breakpoints & Grid
 * 
 * - s .......... 480px Default for extra small devices, phone
 * - m .......... 768px Default for small devices, tablets
 * - l .......... 992px Default for medium devices, desktops
 * - xl ......... 1200px Default for large devices, wide screens
 *
 * Per ciascun breakpoint vengono settati:
 *
 * - query ...... media query
 * - susy ....... personalizzazioni della griglia
 */
$susy: (
		flow : ltr, // rtl | ltr
		math : fluid, // fluid | static
		output : float, // float | isolate
		gutter-position : after, // before | after | split | inside | inside-static
		container : 1600px, // <length> | auto
		container-position : center, // left | center | right | <length> [*2]
		columns : 12, // <number> | <list>
		gutters : 1/1.4, // <ratio>
		column-width : false, // <length> | false/null
		global-box-sizing : border-box, // content-box | border-box
		last-flow : to, // to | from
		debug: (
				image : if($debug == true, show, hide), // show | hide | show-columns | show-baseline
				color : rgba(#66f, .25), // <color>
				output : background, // background | overlay
				toggle : top right, // right | left and top | bottom
		),
		use-custom: (
				background-image : false, // <boolean>
				background-options : false, // <boolean>
				box-sizing : true, // <boolean>
				clearfix : true, // <boolean>
				rem : true, // <boolean>
		),
);


/**
 * Breakpoint
 *
 * @link https://include-media.com/
 */
$breakpoints: (
		xs : 0, // Mobile first
		s : 600px, // Small tablet up
		m : 900px, // Large tablet up
		l : 1200px, // Desktop up
		xl : 1366px // Large desktop up
);


/// Mappa colori
///
/// @prop {Color} body          [#fff]      - body
/// @prop {Color} text          [#767677]   - text
/// @prop {Color} gray-darker   [#1e1e1f]   - gray darker
/// @prop {Color} gray-dark     [#767677]   - gray dark
/// @prop {Color} gray          [#b1b0af]   - gray
/// @prop {Color} gray-light    [#c2c0c0]   - gray light
/// @prop {Color} gray-lighter  [#d4d3d2]   - gray lighter
/// @prop {Color} success       [#5cb85c]   - success
/// @prop {Color} info          [#5bc0de]   - info
/// @prop {Color} warning       [#f0ad4e]   - warning
/// @prop {Color} danger        [#d9534f]   - danger
/// @prop {Color} primary       [#222838]   - primary
/// @prop {Color} secondary     [#e31a4e]   - secondary
/// @prop {Color} tertiary      [#71bdb4]   - tertiary
///
/// @example scss - Usage
/// .foo {
///     color: color(body);
/// }
/// .bar {
///     color: rgba(darken(color(gray), 10%), 0.5);
/// }
///
/// @example css - CSS Output
/// .foo {
///     color: #fff;
/// }
/// .bar {
///     color: hsla(0, 0%, 52%, .5);
/// }
///
/// @type Map
$colors: (
		body : #fff,
		text : (
				base : #2c2d33,
				light : lighten(#2c2d33, 20%),
				dark : #000
		),
		gray-darker : #292728, // Topbar
		gray-dark : #202020, // Titoli
		gray : #bababa, // Testi in grigio (Data articoli e Tabs non evidenziate)
		gray-light : #d8d8d8, // Bordi
		gray-lighter : #f5f5f5, // Sfondi
		success : #0f834d,
		info : #3d9cd2,
		warning : #e29e1c,
		error : #e2401c,
		primary : (
				base : #c32d32,
				light : lighten(#c32d32, 10%),
				dark : darken(#c32d32, 10%)
		),
		secondary : (
				base : #0f834d,
				light : lighten(#0f834d, 10%),
				dark : darken(#0f834d, 10%)
		),
		tertiary : pink,
);


/**
 * Links
 */
$link-color: color(primary, base);
$link-hover-color: color(primary, dark);
$link-visited-color: $link-hover-color;
$link-decoration: underline;
$link-hover-decoration: underline;
$link-visited-decoration: underline;


/**
 * Border radius
 */
$border-radius: 4px;
