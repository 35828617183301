
.webinar {
	width: 100%;

	&__header {
		background-color: #000;
		background-repeat: no-repeat;
		background-size: cover;
		color: #fff;
		text-align: left;
	}

	&__wrap {
		display: grid;
		grid-template-areas: "title" "subtitle" "grant";
	}

	&__title {
		grid-area: title;
	}

	&__subtitle {
		grid-area: subtitle;
	}

	&__grant {
		grid-area: grant;

		background-color: #fff;
		border-radius: 10px;
		color: #000;
		padding: 2rem;

		display: flex;
		align-items: center;

		svg {
			width: 2rem;
			height: 2rem;
			margin-right: 1rem;
			flex: 1 0 auto;
		}

		span {
			display: block;
			width: 100%;
		}
	}

	&__main {
		display: grid;
		grid-gap: 2rem;
		grid-template-areas: "video" "chat";
	}

	&__video {
		grid-area: video;
	}

	&__chat {
		grid-area: chat;
	}

	&__video,
	&__chat {
		width: 100%;

		iframe {
			width: 100%;
		}
	}

	&__info,
	&__comments {
		margin: 0 auto;
		max-width: 800px;
	}

	@include media(">=l") {
		&__wrap {
			grid-template-areas: "title grant" "subtitle grant";
		}

		&__title,
		&__subtitle,
		&__grant {
			margin: 0 4rem;
		}

		&__title,
		&__subtitle {
			justify-self: start;
		}

		&__grant {
			justify-self: end;
			max-width: 300px;
		}
	}

	@include media(">=m") {
		&__main {
			grid-template-columns: repeat(3, 1fr);
			grid-template-areas: "video video chat";
		}
	}

	@include media(">=l") {
		&__main {
			grid-template-columns: repeat(4, 1fr);
			grid-template-areas: "video video video chat";
		}
	}
}
