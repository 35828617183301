////
/// ============================================================================
/// WP PLUGIN: Affiliate WP
/// ============================================================================
///
/// @group vendors
////

#affwp-login-form {}
#affwp-register-form {
    fieldset p {
        &:nth-of-type(4),
        &:nth-of-type(5),
        &:nth-of-type(6) {
            display: none;
        }
    }
}

p.affwp-notice {
    @extend .alert;
    @extend .alert--warning;
    border-bottom-width: 0;
    border-right-width: 0;
    border-top-width: 0;
}