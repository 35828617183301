////
/// ============================================================================
/// WP Visual Composer
///
/// Gestisce le classi del vecchio plugin che utilizzava per formattare il
/// layout nei post
/// ============================================================================
///
/// @group vendors
////

.vc {
    &-row {
        @include clearfix;
    }
    &-column {
        &--1-1 {}
        &--1-2 {
            @include media(">=s") {
                @include gallery(6);
            }
            @include media(">=m") {
                @include gallery(3.25 of 6.5);
            }
        }
        &--1-3 {
            @include media(">=s") {
                @include gallery(4);
            }
            @include media(">=m") {
                @include gallery(2.16 of 6.5);
            }
        }
        &--1-4 {
            @include media(">=s") {
                @include gallery(3);
            }
            @include media(">=m") {
                @include gallery(1.625 of 6.5);
            }
        }
    }
}