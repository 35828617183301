////
/// ============================================================================
/// PLUGIN SUBSCRIPTIONS
/// ============================================================================
///
/// @group woocommerce
///

.entry-card .variations_form {
    display: block;
    width: 100%;
}
.sidebar .entry-card .entry__cart .variations_form {
    margin-top: 0;
}

.subscription-variations {
    &__item {
        margin: 0.5em 0;
        input[type="radio"] {
            position: absolute;
            opacity: 0;
            + span {
                &:before {
                    content: '';
                    background: #fff;
                    border-radius: 100%;
                    border: 1px solid color(gray-light);
                    display: inline-block;
                    width: 1.4em;
                    height: 1.4em;
                    position: relative;
                    top: 0.25em;
                    margin-right: .5em;
                    vertical-align: top;
                    cursor: pointer;
                    text-align: center;
                    transition: all 250ms ease;
                }
            }
            &:checked {
                + span {
                    &:before {
                        background-color: color(primary, base);
                        box-shadow: inset 0 0 0 4px #f4f4f4;
                    }
                }
            }
            &:focus {
                + span {
                    &:before {
                        outline: none;
                        border-color: color(primary, base);
                    }
                }
            }
            &:disabled {
                + span {
                    &:before {
                        box-shadow: inset 0 0 0 4px #f4f4f4;
                        border-color: lighten(color(gray-lighter), 8%);
                        background: lighten(color(gray-lighter), 8%);
                    }
                }
            }
            + span {
                &:empty {
                    &:before {
                        margin-right: 0;
                    }
                }
            }
        }        
    }
}

.woocommerce-variation-price {
    margin-bottom: 1rem;
}