////
/// ============================================================================
/// SOCIAL
///
/// 01. Color Map
/// 02. Social Default
/// 03. Share
///     03a. Custom Block
///     03b. Custom Icon
/// ============================================================================
///
/// @group vendors
////

/**
 * Color Map
 * @link http://brandcolors.net/
 */
$socials: (
		facebook: #3b5998,
		twitter: #1da1f2,
		linkedin: #0077b5,
	//pinterest: #bd081c,
		whatsapp: #075e54
);


/**
 * Social Default
 */
.social {
	list-style: none;
	margin: 0;
	padding: 0;
	text-align: center;

	&__item {
		margin: 0;
		padding: 0;
		position: relative;

		a, span {
			display: block;
		}
	}

	&__link {
		background: transparent;
		border: 0;
		cursor: pointer;
		font-size: 1em;
		height: 100%;
		line-height: 1;
		padding: 0;
	}

	&__icon {
		margin-left: auto;
		margin-right: auto;
		position: relative;
	}

	&__count {
	}
}


/**
 * Share
 */
.share {
	&__total {
	}

	&__total-count {
		display: block;
		font-weight: fontweight-get(bold);
	}

	&__total-text {
		display: block;
		opacity: 0.6;
		text-transform: uppercase;
	}

	&__social {
	}


	/**
	 * Custom Block
	 */
	&--block {
		margin-left: auto;
		margin-right: auto;
		max-width: 230px;

		.share__total {
			background: color(gray-darker);
			color: #fff;
			padding: 2rem 0;
			text-align: center;

			&-count {
				@include typography($h2);
				font-weight: fontweight-get(bold);
			}

			&-text  {
				font-size: 0.8rem;
			}
		}

		.social {
			display: flex;
			justify-content: center;

			&__item {
				@include aspect-ratio(1 1);
				border-bottom: 1px solid color(gray-light);
				border-left: 1px solid color(gray-light);
				width: (100% / 3);

				&:last-child {
					border-right: 1px solid color(gray-light);
				}

				@each $name, $color in $socials {
					&--#{$name} .social__count {
						background: $color;
					}
				}

				&--twitter .social__count {
					font-size: 16px;
				}
			}

			&__link {
				@include absolute(top 0 left 0);
				overflow: hidden;
				width: 100%;

				&:hover {
					.social__icon {
						transform: translateY(-100%);
					}

					.social__count {
						bottom: 0;
					}
				}
			}

			&__icon,
			&__count {
				align-items: center;
				display: flex;
				height: 100%;
				justify-content: center;
				transition: bottom 0.3s ease-in-out;
				width: 100%;
			}

			&__icon {
				color: color(gray);

				svg {
					@include transform();
					height: 40%;
					width: 40%;
				}
			}

			&__count {
				@include absolute(bottom -100% left 0);
				color: #fff;
				font-size: 20px;
				font-weight: fontweight-get(bold);

				span {
					@include transform();
				}

			}
		}
	}


	/**
	 * Custom Icon
	 */
	&--icon {
		.social {
			display: flex;
			flex-direction: row;
			justify-content: center;

			&__item {
				height: 4rem;
				width: 4rem;

				@each $name, $color in $socials {
					&--#{$name} button {
						background: $color;
					}
				}
			}

			&__link {
				width: 100%;
			}

			&__icon {
				color: #fff;
				height: 100%;

				svg {
					@include transform();
					height: 50%;
					width: 50%;
				}
			}

			&__count {
				display: none;
			}
		}
	}
}