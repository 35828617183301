////
/// ============================================================================
/// HOME
///
/// 01. Featured
/// 02. Books
/// ============================================================================
///
/// @group pages
////

/**
 * Featured
 */
.home-featured {
    &__posts {
        margin-bottom: 2rem;
        width: 100%;
        @include media(">=l") {
            @include span(9);
            margin-bottom: 0;
        }
        .entry {
            @include media(">=m") {
                @include span(6);
                @include n_first(2) {
                    margin-top: 0;
                }
                @include each(2) {
                    @include last();
                }
            }
            @include media(">=l") {
                margin-right: gutter(9);
                width: span(4.5 of 9);
            }
        }
    }
    &__ads {
        align-items: center;
        display: inline-flex;
        border: 1px solid color(gray-light);
        justify-content: center;
        left: 50%;
        padding: 0.5rem;
        position: relative;
        transform: translateX(-50%);
        @include media(">=l") {
            @include span(last 3);
            left: inherit;
            transform: inherit;
        }
    }
}


/**
 * Books
 */
.home-books {
    overflow: hidden;
    position: relative;

    &__wrap {
        @include clearfix();
        align-items: stretch;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        z-index: 1;
    }
    &__header {
        margin-bottom: 2rem;
        padding-left: gutter();
        padding-right: gutter();
        width: 100%;
        @include media(">=l") {
            @include span(2.5);
            margin-bottom: 0;
            padding-left: 0;
            padding-right: 0;
            padding-top: 2rem;
        }
        @include media(">=#{map-get($breakpoints, l)}", "<=#{map-get($susy, container)}") {
            margin-left: gutter();
        }
        @include media(">=#{map-get($susy, container)}") {
            margin-left: calc(#{gutter()} + ((100% - #{map-get($susy, container)}) / 2));
        }

        .swiper-pagination-bullets {
            @include media(">=l") {
                text-align: left;
            }
        }
        .swiper-pagination-bullet:before {
            background: #fff;
        }
    }
    &__content {
        padding-left: gutter();
        padding-right: gutter();
        width: 100%;
        @include media(">=l") {
            padding-left: 0;
            padding-right: 0;
            width: (100% - (span(2.5) + gutter()));
        }
        @include media(">=#{map-get($breakpoints, l)}", "<=#{map-get($susy, container)}") {
            width: (100% - (span(2.5) + gutter() + gutter()));
        }
        @include media(">=#{map-get($susy, container)}") {
            width: calc(100% - (#{span(2.5) + gutter() + gutter()} + ((100% - #{map-get($susy, container)}) / 2)));
        }
    }
}