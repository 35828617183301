////
/// ============================================================================
/// BREADCRUMB
/// ============================================================================
///
/// @group components
////

.breadcrumb {
    color: #fff;
    font-size: 12px;
    font-weight: fontweight-get(bold);
    text-transform: uppercase;

    a {
        color: #fff;
        padding-right: 0.5em;
        opacity: 0.7;
        text-decoration: none;
    }

    span span span a,
    strong {
        padding-left: 0.5em;
    }

    &__wrap {}
}