////
/// ============================================================================
/// CUSTOM - MISCELLANEA
///
/// 01. Meta
/// 02. Video
/// 03. Play
/// 04. Read More
/// ============================================================================
///
/// @group components
////


/**
 * Meta
 */
.meta {
	position: relative;
	width: 100%;

	.avatar {
		@include center(left, 60px, 60px);

		img {
			border: 2px solid #fff;
			border-radius: 50%;
		}
	}

	a.avatar:hover {
		border-color: color(primary, base);
	}

	svg {
		height: 1rem;
		width: 1rem;
	}
}


/**
 * Video
 */
.video {
	background: color(gray-darker);
	position: relative;

	&__play {
	}

	&__image,
	&__iframe,
	iframe {
		@include absolute(top 0 left 0);
		height: 100%;
		width: 100%;
	}

	&__image {
		cursor: pointer;

		img {
			height: inherit;
			object-fit: cover;
		}

		&:hover {
			.video__play {
			}
		}

		&:before {
			@include absolute(top 0 left 0, 1);
			background: rgba(#000, 0.4);
			content: '';
			height: 100%;
			width: 100%;
		}
	}

	&__iframe {
		z-index: 0;
	}
}


/**
 * Play
 */
.play {
	@include center(center, 80px, 80px, 1);
	background: rgba(#000, 0.3);
	border-radius: 40px;
	color: #fff;

	&--small {
		border-radius: 30px;
		height: 60px;
		margin-left: -30px;
		margin-top: -30px;
		width: 60px;
	}

	&--large {
		border-radius: 70px;
		height: 140px;
		margin-left: -70px;
		margin-top: -70px;
		width: 140px;
	}

	svg {
		@include transform();
		height: 70%;
		width: 70%;
	}
}


/**
 * Read More
 */
.read-more {
	font-style: italic;
}


/**
 * Serparator
 */
.separator {
	margin: 2rem auto;
	max-width: 400px;
	position: relative;
	text-align: center;
	width: 100%;

	&__mask {
		display: block;
		height: 0.5rem;
		overflow: hidden;

		&::after {
			border-radius: 125px / 12px;
			box-shadow: 0 0 8px rgba(#000, 0.8);
			content: '';
			display: block;
			height: 0.5rem;
			margin: -0.5rem auto 0;
			width: 100%;
		}
	}

	&__text {
		@include absolute(bottom 100% left 50%);
		background: #fff;
		color: color(gray-dark);
		font-size: 12px;
		height: 1rem;
		line-height: 1rem;
		margin-bottom: -0.7rem;
		padding: 0 10px;
		text-transform: uppercase;
		transform: translateX(-50%);
	}
}


/**
 * Select Menu
 *
 * @link https://codyhouse.co/gem/css-multi-level-accordion-menu/
 */
.select-menu {
	position: relative;

	ul {
		list-style: none;
		padding-left: 0;
	}

	&__checkbox {
		opacity: 0;
		position: absolute;

		&:checked + label + .select-menu__panel,
		&:checked + label:nth-of-type(n) + .select-menu__panel {
			display: block; // use label:nth-of-type(n) to fix a bug on safari (<= 8.0.8) with multiple adjacent-sibling selectors
		}

		@at-root ul &:checked + label svg {
			transform: rotate(-180deg);
		}
	}

	&__label {
		color: color(gray-darker);
		cursor: pointer;
		display: block;
		font-weight: fontweight-get(bold);
		padding: 1rem 3em 1rem 1em;
		position: relative;

		svg {
			@include center(right 1em, 24px, 24px, 1);
			transition: all .5s;
		}

		&--root {
			background: #fff;
			border: 1px solid color(gray-light);
			border-radius: $border-radius;
			color: color(text, light);
			font-size: 1.2rem;
			font-weight: fontweight-get(regular);
			line-height: 1.15;
		}
	}

	&__panel {
		display: none;
		margin: 0 3em 1rem 2em;
		position: relative;

		&::before {
			@include absolute(top 0 left -1em);
			background: color(gray-light);
			content: '';
			display: block;
			height: 100%;
			width: 1px;
		}

		&--root {
			background: #fff;
			border: 1px solid color(gray-light);
			margin: -1px 0 0;
			padding: 0;
			position: absolute;
			width: 100%;
			z-index: 100;

			&::before {
				display: none;
			}

			& > .select-menu__item {
				border-bottom: 1px solid color(gray-light);

				&:last-child {
					border-bottom: 0;
				}
			}
		}
	}

	&__item {
	}

	&__link {
		color: color(text, light);
		text-decoration: none;

		&:hover {
			color: $link-hover-color;
			text-decoration: none;
		}
	}
}


/**
 * Dettagli Evento
 */
.c-event {
	dl {
		align-items: flex-start;
		display: flex;
		flex-wrap: wrap;
	}

	dt {
		font-weight: fontweight-get(bold);
		width: 100%;
		@include media(">=s") {
			padding-right: 1em;
			width: 35%;
		}
	}

	dd {
		margin: 0;
		width: 100%;
		@include media(">=s") {
			width: 65%;
		}
	}
}


/**
 * Place
 */
.c-place {
	&__map {
		height: 22rem;
	}

	&__title {
	}

	&__address {
	}

	.infowindow {
		padding: 10px;

		h4 {
			margin-top: 0;
		}

		p {
			margin-bottom: 0;
		}
	}
}


/**
 * Accordion
 */
.c-accordion {
	&__item {
		margin-bottom: -1px;
		transition: all 0.6s;

		&:last-child {
			border-bottom: 1px solid color(gray-light);
		}

		&.is-open {
			border-bottom: none;

			.c-accordion__title {
				color: color(primary, base);
			}

			.c-accordion__icon {
				color: color(primary, base);

				svg.minus {
					opacity: 1;
				}

				svg.plus {
					opacity: 0;
				}
			}
		}
	}

	&__header {
		align-items: center;
		background: #fff;
		border-top: 1px solid color(gray-light);
		cursor: pointer;
		display: flex;
		padding: 1.8rem 0 1.2rem;
		position: relative;
	}

	&__content {
		background: #fff;
		border-bottom: 1px solid color(gray-light);

		&-inner {
			padding: 1rem 42px 3rem 42px;

			p {
				&:first-child {
					margin-top: 0;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	&__title {
		flex: 1 1 auto;
		margin: 0;
		padding-left: 10px;
	}

	&__icon {
		flex: 0 0 auto;
		height: 32px;
		position: relative;
		width: 32px;

		svg {
			@include absolute(top 0 left 0);
			height: 100%;
			width: 100%;

			&.minus {
				opacity: 0;
			}
		}
	}
}


/**
 * Programma
 */
.c-program {
	@include clearfix();
	border-top: 1px solid color(gray-light);
	margin-top: 1rem;
	padding: 1rem;

	&:first-child {
		border-top: 0;
		margin-top: 0;
	}

	&__left {
		margin-bottom: 2rem;
		@include media(">=s") {
			float: left;
			margin-bottom: 0;
			max-width: 150px;
		}

		h5, p {
			line-height: 1;
			margin-bottom: 0;
		}
	}

	&__right {
		@include media(">=s") {
			margin-left: 150px;
			padding-left: 2em;
		}

		p, ul, ol {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__avatar {
		margin-bottom: 1rem;
	}
}