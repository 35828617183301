////
/// ============================================================================
/// PLUGIN STRIPE
/// ============================================================================
///
/// @group woocommerce
///

form#order_review #payment_method_stripe,
form#order_review .payment_methods label {
    margin: 0 !important;
}

.woocommerce-checkout #payment .payment_method_stripe label[for=payment_method_stripe],
#add_payment_method #payment .payment_method_stripe label[for=payment_method_stripe] {
    padding-left: 1em !important;
}

/* My Account / Payment methods */
.woocommerce-MyAccount-content #payment .payment_methods .payment_method_stripe .payment_box {
    padding-left: 3rem;
    padding-top: 0;
}

#payment .payment_methods > .wc_payment_method > label:before,
#payment .payment_methods > li > label:before {
    margin-right: 0;
    width: 2rem;
}
