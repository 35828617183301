////
/// ============================================================================
/// TYPOGRAPHY
///
/// 01. Base Font
/// 02. Links
/// 03. Typography
/// 04. Navigation
/// ============================================================================
///
/// @group layout
////

/**
 * Font Face
 *
 * @see https://www.zachleat.com/web/the-compromise/
 */
@font-face {
	font-family: 'Roboto Condensed';
	src: url('fonts/RobotoCondensed-Regular.woff2') format('woff2');
	font-display: swap;
}

@font-face {
	font-family: 'Roboto Condensed';
	src: url('fonts/RobotoCondensed-Bold.woff2') format('woff2');
	font-weight: 700;
	font-display: swap;
}

@font-face {
	font-family: 'PT Sans';
	src: url('fonts/PTSans-Regular.woff2') format('woff2');
	font-display: swap;
}

@font-face {
	font-family: 'PT Sans';
	src: url('fonts/PTSans-Bold.woff2') format('woff2');
	font-weight: 700;
	font-display: swap;
}

/**
 * Base Font
 */
body {
	@include typography-base;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

.fonts-loaded {
	body {
		font-family: "PT Sans", sans-serif;
	}

	b, strong {
		font-family: "PT Sans", sans-serif;
		font-weight: 700;
	}

	h1,
	h2,
	h1 strong,
	h2 strong,
	h3 strong,
	h4 strong,
	h5 strong,
	h6 strong,
	.comment-reply-title {
		font-family: "Roboto Condensed", sans-serif;
		font-weight: 700;
	}

	h3,
	h4,
	h5,
	h6,
	.u-heading-font {
		font-family: "Roboto Condensed", sans-serif;
		font-weight: 400;
	}
}


/**
 * Links
 */
a {
	color: $link-color;
	text-decoration: $link-decoration;

	&:hover {
		color: $link-hover-color;
		text-decoration: $link-hover-decoration;
	}
}


/**
 * Typography
 */
h1 {
	@include typography($h1);
	margin-bottom: 0;
}

h2 {
	@include typography($h2);
	margin-bottom: vr(1, $map: $h2);
}

h3 {
	@include typography($h3);
	margin-bottom: vr(1.5, $map: $h3);
	margin-top: vr(0.5, $map: $h3);
}

h4 {
	@include typography($h4);
	margin-bottom: vr(1.5, $map: $h4);
	margin-top: vr(0.5, $map: $h4);
}

h5 {
	@include typography($h5);
	margin-bottom: vr(1.5, $map: $h5);
	margin-top: vr(0.5, $map: $h5);
}

h6 {
	@include typography($h6);
	margin-bottom: vr(1.5, $map: $h6);
	margin-top: vr(0.5, $map: $h6);
}

blockquote,
p,
ul,
ol {
	margin-bottom: vr(1);
	margin-top: vr(1);
}

ul, ol {
	padding-left: 20px;
}

p,
div,
ul, ol {
	&.alignleft {
		text-align: left;
	}

	&.alignright {
		text-align: right;
	}

	&.aligncenter {
		text-align: center;
	}
}


/**
 * Navigation
 */
nav {
	ul, ol {
		@include reset(list);
	}

	a, a:hover {
		text-decoration: none;
	}
}


/**
 * Citation
 */
blockquote {
	color: color(primary, base);
	font-weight: fontweight-get(bold);
}