////
/// ============================================================================
/// PLUGIN ARG Multistep
/// ============================================================================
///
/// @group woocommerce
///

@media screen and (min-width: 992px) {
    .argmc-wrapper .argmc-form-steps.register-visible {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.argmc-order-payment-step {
    max-width: none;
}

.woocommerce-order {
    margin: auto;
    max-width: 1000px;
}

.woocommerce-checkout .woocommerce .argmc-wrapper .input-checkbox,
.woocommerce-checkout .woocommerce .argmc-wrapper #rememberme {
    top: -2px;
}

/**
 * Font-size e line-height
 */
.woocommerce-checkout .woocommerce .argmc-wrapper .woocommerce-form__label-for-checkbox,
.woocommerce-checkout .woocommerce .argmc-wrapper label,
.woocommerce-checkout .woocommerce .argmc-wrapper #payment div.payment_box p {
    font-size: inherit !important;
    line-height: inherit !important;
}

// Coupon
.argmc-form-steps {
    #coupons_list {
        text-align: center;
        @media screen and (min-width: 767px) {
            margin: auto;
            max-width: 600px;
        }
    }
}

.woocommerce-checkout .woocommerce .argmc-wrapper form.checkout_coupon {
    border: none !important;
}

.woocommerce-shipping-fields,
.woocommerce-additional-fields {
    @include clearfix;
}

.woocommerce-checkout .woocommerce .argmc-wrapper form .form-row#billing_address_2_field,
.woocommerce-checkout .woocommerce .argmc-wrapper form .form-row#shipping_address_2_field {
    margin-top: -8px;
    padding-top: 0;
    @media screen and (min-width: 767px) {
        margin-top: 48px !important;
    }
}

.woocommerce-checkout .woocommerce .argmc-wrapper ul#shipping_method {
    white-space: normal !important;

    li input[type=radio].shipping_method + label {
        font-weight: normal !important;
        text-transform: none !important;
    }
}

.woocommerce-checkout .woocommerce .argmc-wrapper #payment .payment_methods input[type=radio].input-radio+label:before,
.woocommerce-checkout .woocommerce .argmc-wrapper ul#shipping_method li input[type=radio].shipping_method+label:before,
.woocommerce-checkout .woocommerce .argmc-wrapper #payment .payment_methods input[type=radio].input-radio + label:after,
.woocommerce-checkout .woocommerce .argmc-wrapper ul#shipping_method li input[type=radio].shipping_method + label:after {
    top: 1em !important;
}