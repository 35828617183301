////
/// ============================================================================
/// WOOCOMMERCE CHECKOUT
/// ============================================================================
///
/// @group woocommerce
///

#order_review_heading,
#order_review {
	@include media(">=m") {
		@include span(last 6);
		clear: right;
	}
}

form.checkout {
	@include clearfix();
	position: static !important; /* 1 */

	> .blockUI {
		position: fixed !important; /* 1 */
	}
}

.checkout_coupon {
	margin-bottom: 2.5rem;
}

.woocommerce-invoice-fields__field-wrapper {
	clear: both;
}

#payment {
	@include clearfix();

	.create-account {
		clear: both;
	}

	.payment_methods {
		margin: 0 !important;
		padding: 0 !important;

		> .wc_payment_method,
		> li {
			> label {
				cursor: pointer;
				display: block;
				margin: 0;
				padding: 1rem;

				&:before {
					@extend %woocommerce-icon;
					content: "\e901";
					margin-right: 0.5em;
					transition: color, ease, .2s;
				}
			}

			li > input[type=radio]:first-child,
			& > input[type=radio]:first-child {
				@include hide(visuallyhidden);

				&:checked + label:before {
					color: color(primary, base);
					content: "\e903";
				}
			}
		}

		li {
			list-style: none !important;
			margin-left: 0;
			position: relative;

			img {
				border: 0;
				float: right;
				max-height: 2rem;
				padding: 0;
				width: auto; // Force img[src*=svg]
			}

			&.wc_payment_method > input[type=radio]:first-child {
				@include hide(visuallyhidden);

				&:checked + label:before {
					color: color(primary, base);
					content: "\e903";
				}
			}

			&:last-child:not(.woocommerce-notice) {
				border-bottom: 0;
				padding-bottom: 0;
			}

			p {
				margin: 0;
			}

			.payment_box {
				padding: 1rem;

				fieldset {
					border: 0;
					//font-size: 0.85rem;
					margin: 0;
					padding: 1em 0 0 0;

					label {
						margin-left: 0;
						width: auto;
					}

					.form-row {
						margin-bottom: 1em;

						input[type="checkbox"] {
							margin-right: 5px;
						}
					}

					#cvv {
						width: 22% !important;
					}

					.help {
						float: right;
						width: 75%;
					}
				}

				.woocommerce-SavedPaymentMethods {
					margin: 0;
					padding-top: 1em;

					li {
						margin-top: 0.5rem;

						input[type=radio] {
							margin-right: .236em;
						}
					}
				}

				ul.wc-saved-payment-methods {
					margin-left: 0;
				}

				.wc-credit-card-form-card-number,
				.wc-credit-card-form-card-expiry,
				.wc-credit-card-form-card-cvc {
					//font-size: ms(2);
				}

				//.wc-credit-card-form-card-number {
				//	background-repeat: no-repeat;
				//	background-position: right center;
				//	background-size: 31px 20px;
				//	background-image: url("images/credit-cards/unknown.svg");
				//
				//	&.visa {
				//		background-image: url("images/credit-cards/visa.svg");
				//	}
				//	&.mastercard {
				//		background-image: url("images/credit-cards/mastercard.svg");
				//	}
				//	&.laser {
				//		background-image: url("images/credit-cards/laser.svg");
				//	}
				//	&.dinersclub {
				//		background-image: url("images/credit-cards/diners.svg");
				//	}
				//	&.maestro {
				//		background-image: url("images/credit-cards/maestro.svg");
				//	}
				//	&.jcb {
				//		background-image: url("images/credit-cards/jcb.svg");
				//	}
				//	&.amex {
				//		background-image: url("images/credit-cards/amex.svg");
				//	}
				//	&.discover {
				//		background-image: url("images/credit-cards/discover.svg");
				//	}
				//}
			}
		}
	}

	.terms {
		clear: both;
		float: none;
		margin-bottom: 1em;
		width: 100%;

		label {
			cursor: pointer;
			display: block;
		}
	}

	.woocommerce-terms-and-conditions {
		background-color: rgba(#000, 0.05);
		box-shadow: inset 0 1px 3px rgba(#000, 0.2);
		margin-bottom: 16px;
		padding: 1rem;
	}

	.place-order {
		float: none;
		margin-bottom: 0;
		margin-top: 2.5rem;
		padding: 1.5rem;
		width: 100%;

		.button {
			font-size: 1.5rem;
			padding-bottom: 1rem;
			padding-top: 1rem;
			white-space: pre-wrap;
			width: 100%;
		}
	}
}

table.woocommerce-checkout-review-order-table {
	.product-name {
		width: 300px;
		word-wrap: break-word;
	}
}

@include media(">=m") {
	.woocommerce-order-pay {
		#order_review {
			float: none;
			width: 100%;

			#payment {
				h3 {
					padding-left: 1em;
					padding-top: 1em;
				}
			}

			.payment_methods + .form-row {
				padding: 1em;
			}

			.button#place_order {
				display: block;
				width: 100%;
			}
		}
	}
}


/**
 * Nascondi elementi in Checkout
 */
.woocommerce-checkout {
	&, .l-main__content {
		padding-top: 3rem;
	}

	.woocommerce {
		padding-bottom: 3rem;
	}

	.header__topbar,
	.header__navigation,
	.l-main__header,
	.footer {
		display: none;
	}

	.header__logo {
		left: auto;
		margin: 0 auto;
		position: relative;
		top: auto;
	}

	.header__headline {
		box-shadow: none;
	}
}

.woocommerce .form-row label {
	line-height: 1.2;
}

p.mailchimp-newsletter {
	display: flex;
	font-size: 1.2rem;
	line-height: 1.1;

	input {
		margin-right: 0.5em;
		margin-top: 3px;
	}

	label.inline {
		margin: 0;
	}
}

#need-invoice,
#ship-to-different-address {
	margin-top: 0;

	label {
		display: flex;
		font-size: 1.2rem;
		line-height: 1.2;

		input {
			margin-top: 3px;
		}
	}
}