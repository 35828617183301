////
/// ============================================================================
/// CUSTOM - FORM
///
/// 01. Base
/// 02. Custom WPCF7
/// 03. Required
/// 04. Search
/// ============================================================================
///
/// @group components
////

/**
 * Base
 */
.form {
    &__fieldset {
        @include clearfix;
        &--captcha {
            input {
                display: inline-block;
                width: 65%;
            }
            img {
                float: right;
                margin-top: 12px;
            }
        }
    }
    &__group {
        margin-bottom: 1rem;
        position: relative;
        .privacy {
            display: block;
            padding-left: 1.5em;
            input {
                left: 0;
            }
        }
    }
    &__label {}
    &__actions {
        @include clearfix;
        text-align: center;
    }

    /* Comments Respond */
    &--respond {
        .form__fieldset--standard {
            @include media(">=s") {
                .form__group {
                    @include span(6);
                    &:last-child {
                        @include last;
                    }
                }
            }
            @include media(">=l") {
                .form__group {
                    @include span(4 of 8);
                }
            }
        }

    }


    /**
     * Required
     */
    .required {
        @include absolute(top 10px right 0);
        display: block;
        color: color(error);
        font-size: 12px;
        text-transform: uppercase;
    }
}


/**
 * Custom WPCF7
 */
.wpcf7-not-valid-tip {
    @include typography($h4);
    color: color(error);
    display: block;
}
.wpcf7-list-item-label {
    @include typography($h4);
}
.screen-reader-response {
    @include hide(visuallyhidden);
    @extend .alert;
    @extend .alert--error;
}
.wpcf7-validation-errors {
    @extend .alert;
    @extend .alert--error;
    margin-top: 1rem;
}


/**
 * Search
 */
.search-form {
    &__group {
        align-items: stretch;
        display: flex;
        width: 100%;
    }
    &__query,
    &__button {
        font-size: 1.2rem;
    }
    &__query {
        border-radius: $border-radius 0 0 $border-radius;
        border-right: 0;
        box-shadow: none;
        flex: 1 1 0;
        height: auto;
        min-width: 0;
        padding-bottom: 1rem;
        padding-top: 1rem;
    }
    &__button {
        color: #fff;
        display: flex;
        flex: 0 0 auto;
        position: relative;
        white-space: nowrap;
        width: 100px;
        .btn {
            align-self: stretch;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            height: auto;
            padding: 0;
            width: 100%;
        }
        svg {
            @include transform();
            height: 50%;
            margin-left: 0;
            user-select: none;
            width: 50%;
        }
    }
}
