////
/// ============================================================================
/// FAMILY
///
/// 01. First ....................... n_first() // Rinominata rispetto l'originale perchè va in conflitto con gallery() di susy
/// 02. Last ........................ n_last() // Rinominata rispetto l'originale perchè va in conflitto con gallery() di susy
/// 03. After First ................. after-first()
/// 04. From End .................... form-end()
/// 05. Between ..................... between()
/// 06. Even Between ................ even-between()
/// 07. Odd Between ................. odd-between()
/// 08. N Between ................... n-between()
/// 09. All But ..................... all-but()
/// 10. Each ........................ each()
/// 11. Every ....................... every()
/// 12. Form First Last ............. form-first-last()
/// 13. Middle ...................... middle()
/// 14. All But First Last .......... all-but-first-last()
/// 15. First Of .................... first-of()
/// 16. Last Of ..................... last-of()
/// 17. At Least .................... at-least()
/// 18. At Most ..................... at-most()
/// 19. In Between .................. in-between()
/// 20. First Child ................. first-child()
/// 21. Last Child .................. last-child()
/// 22. Even ........................ even()
/// 23. Odd ......................... odd()
/// 24. First Last .................. first-last()
/// 25. Unique ...................... unique()
/// 26. Only ........................ only()
/// 24. Not Unique .................. not-unique()
/// 28. Child Index ................. child-index()
/// 29. Order Index ................. order-index()
/// ============================================================================
///
/// @link https://github.com/LukyVj/family.scss/blob/master/source/src/family.scss
///
/// @group family
////


/// First
///
/// Select all children from the first to `$num`.
///
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
/// @parameter {number} $num - id of the child
@mixin n_first($num) {
  @if $num == 1 {
    &:first-child {
      @content;
    }
  }
  @else {
    &:nth-child(-n + #{$num}) {
      @content;
    }
  }
}


/// Last
///
/// Select all children from the last to `$num`.
///
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
/// @parameter {number} $num - id of the child
@mixin n_last($num) {
  &:nth-last-child(-n + #{$num}) {
    @content;
  }
}


/// After First
///
/// Select all children after the first to `$num`.
///
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
/// @parameter {number} $num - id of the child
@mixin after-first($num) {
  &:nth-child(n + #{$num + 1}) {
    @content;
  }
}


/// From End
///
/// Select all children before `$num` from the last.
///
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
/// @parameter {number} $num - id of the child
@mixin from-end($num) {
  &:nth-last-child(#{$num}) {
    @content;
  }
}


/// Between
///
/// Select all children between `$first` and `$last`.
///
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
@mixin between($first, $last) {
  &:nth-child(n + #{$first}):nth-child(-n + #{$last}) {
    @content;
  }
}


/// Even Between
///
/// Select all even children between `$first` and `$last`.
///
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
@mixin even-between($first, $last) {
  &:nth-child(even):nth-child(n + #{$first}):nth-child(-n + #{$last}) {
    @content;
  }
}


/// Odd Between
///
/// Select all odd children between `$first` and `$last`.
///
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
@mixin odd-between($first, $last) {
  &:nth-child(odd):nth-child(n + #{$first}):nth-child(-n + #{$last}) {
    @content;
  }
}


/// N Between
///
/// Select all `$num` children between `$first` and `$last`.
///
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
@mixin n-between($num, $first, $last) {
  &:nth-child(#{$num}n):nth-child(n + #{$first}):nth-child(-n + #{$last}) {
    @content;
  }
}


/// All But
///
/// Select all children but `$num`.
///
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
/// @parameter {number} $num - id of the child
@mixin all-but($num) {
  &:not(:nth-child(#{$num})) {
    @content;
  }
}


/// Each
///
/// Select children each `$num`.
///
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
/// @parameter {number} $num - id of the child
/// @alias every
@mixin each($num) {
  &:nth-child(#{$num}n) {
    @content;
  }
}


/// Every
///
/// Select children each `$num`.
///
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
/// @parameter {number} $num - id of the child
@mixin every($num) {
  &:nth-child(#{$num}n) {
    @content;
  }
}


/// Form First Last
///
/// Select the `$num` child from the start and the `$num` child from the last.
///
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
/// @parameter {number} $num - id of the child
@mixin from-first-last($num) {
  &:nth-child(#{$num}),
  &:nth-last-child(#{$num}) {
    @content;
  }
}


/// Middle
///
/// Select the item in the middle of `$num` child. Only works with odd number chain.
///
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
/// @parameter {number} $num - id of the child
@mixin middle($num) {
  &:nth-child(#{round($num / 2)}){
    @content;
  }
}


/// All But First Last
///
/// Select all children between the `$num` first and the `$num` last.
///
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
/// @parameter {number} $num - id of the child
@mixin all-but-first-last($num) {
  &:nth-child(n + #{$num}):nth-last-child(n + #{$num}) {
    @content;
  }
}


/// First Of
///
/// This I/O mixin will only select the first of `$limit` items. It will not
/// work if there is not as much as item as you set in `$limit`.
///
/// @parameter {number} $limit
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
@mixin first-of($limit) {
  &:nth-last-child(#{$limit}):first-child {
   @content;
  }
}


/// Last Of
///
/// This I/O mixin will only select the last of `$limit` items. It will not
/// if there is not as much as item as you set in `$limit`.
///
/// @parameter {number} $limit
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
@mixin last-of($limit) {
  &:nth-of-type(#{$limit}):nth-last-of-type(1) {
    @content;
  }
}


/// At Least
///
/// This quantity-query mixin will select every items if there is at least `$num` items. It will not
/// if there is not as much as item as you set in `$num`.
///
/// @parameter {number} $limit
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
@mixin at-least($num) {
  $selector: &;
  $child: nth(nth($selector, -1), -1);
  &:nth-last-child(n + #{$num}),
  &:nth-last-child(n + #{$num}) ~ #{$child} {
    @content;
  }
}


/// At Most
///
/// This quantity-query mixin will select every items if there is at most `$num` items. It will not
/// if there is not as much as item as you set in `$num`.
///
/// @parameter {number} $limit
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
@mixin at-most($num) {
  $selector: &;
  $child: nth(nth($selector, -1), -1);
  &:nth-last-child(-n + #{$num}):first-child,
  &:nth-last-child(-n + #{$num}):first-child ~ #{$child} {
    @content;
  }
}


/// In Between
///
/// This quantity-query mixin will select every items only if there is between `$min` and `$max` items.
///
/// @parameter {number} $limit
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
@mixin in-between($min, $max) {
  $selector: &;
  $child: nth(nth($selector, -1), -1);
  &:nth-last-child(n + #{$min}):nth-last-child(-n + #{$max}):first-child,
  &:nth-last-child(n + #{$min}):nth-last-child(-n + #{$max}):first-child ~ #{$child} {
    @content;
  }
}


/// First Child
///
/// Select the first exact child
///
/// @group no-arguments
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
@mixin first-child() {
  &:first-of-type {
    @content
  }
}

/// Last Child
///
/// Select the last exact child
///
/// @group no-arguments
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
@mixin last-child() {
  &:last-of-type {
    @content
  }
}


/// Even
///
/// Select all even children.
///
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
@mixin even() {
  &:nth-child(even) {
    @content;
  }
}


/// Odd
///
/// Select all odd children.
///
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
@mixin odd() {
  &:nth-child(odd) {
    @content;
  }
}


/// First Last
///
/// Select only the first and last child.
///
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
@mixin first-last() {
  &:first-child,
  &:last-child {
    @content;
  }
}


/// Unique
///
/// Will only select the child if it’s unique.
///
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
/// @alias only
@mixin unique() {
  &:only-child {
    @content;
  }
}


/// Only
///
/// Will only select the child if it’s unique.
///
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
@mixin only() {
  &:only-child {
    @content;
  }
}


/// Not Unique
///
/// Will only select children if they are not unique. Meaning if there is at
/// least 2 children, the style is applied.
///
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
@mixin not-unique() {
  &:not(:only-child) {
    @content;
  }
}


/// Child Index
///
/// This mixin is used to automatically sort z-index in numerical order. But it
/// can also sort them in anti-numerical order, depending the parameters you use.
///
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
/// @parameter {number} $num - Number of children
/// @parameter {string} $direction [forward] - Direction of the sort
/// @parameter {number} $index [0] - Index of the sorting
@mixin child-index($num, $direction: 'forward', $index: 0) {
  @for $i from 1 through $num {
    @if ($direction == 'forward') {
      &:nth-child(#{$i}) {
        z-index: order-index($i, $index);
        @content;
      }
    } @else if ($direction == 'backward') {
      &:nth-last-child(#{$i}) {
        z-index: order-index($i, $index);
        @content;
      }
    }
  }
}


/// Order Index
///
/// Used by the child-index mixin. It will returned the proper sorted numbers
/// depending on the `$index` value.
///
/// @access private
/// @parameter {number} $num - Number of children
/// @parameter {number} $index - Index of the sorting
@function order-index($i, $index) {
  @return ($index + $i);
}
