////
/// ============================================================================
/// Author
///
/// 01. Author List
/// 02. Scacchi
/// ============================================================================
///
/// @group components
////


/**
 * Author List
 */
.c-author-list {
    &__item {
        width: 100%;
        @include media(">=m") {
            width: 50%;
        }
        @include media(">=l") {
            width: (100% / 3);
        }

        a {
            align-items: center;
            color: color(text, base);
            display: flex;
            height: 100%;
            padding: 1rem;
            text-decoration: none;
            &:hover {
                background: darken(color(gray-lighter), 3%);
                .c-author-list__avatar {
                    filter: grayscale(0);
                }
                .c-author-list__name {
                    color: color(primary, base);
                }
            }
        }

        span {
            display: block;
        }
    }
    &__avatar {
        filter: grayscale(100%);
        flex: 0 0 120px;
        margin-right: 1em;
        width: 120px;
    }
    &__content {
        flex: 0 1 auto;
    }
    &__name {
        margin-bottom: 0.5rem;
    }
    &__description {
        margin-bottom: 0.5rem;
    }
}


/**
 * Scacchi
 */
.u-chess {
    overflow: hidden;
    list-style: none;
    margin: 0;
    padding: 0;
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    &__item {
        @include relative(bottom -1px left -1px);
        border-left: 1px solid color(gray-light);
        border-bottom: 1px solid color(gray-light);
    }
}


/**
 * Author Intro
 *
 * Introduzione presente nella pagina autore
 */
.c-author-info {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    &__text {
        @include media(">=m") {
            padding-right: 2em;
            width: 50%;
        }
    }
    &__avatar {
        @include media(">=m") {
            width: 50%;
        }
    }
}


/**
 * Author Social
 */
.c-author-social {
    list-style: none;
    margin: 0;
    padding-left: 0;
    &__item {
        border-top: 1px solid color(gray-light);
        &:first-of-type {
            border-top: none;
        }
        a {
            align-items: center;
            color: color(text, base);
            display: flex;
            padding: 0.5rem 0;
            text-decoration: none;
            &:hover {
                color: $link-hover-color;
            }
            svg {
                flex: 0 0 auto;
                height: 1.5em;
                margin-right: 1em;
                width: 1.5em;
            }
        }
    }
}