////
/// ============================================================================
/// FUNCTIONS
///
/// 01. Valid List ............. contains()
/// 02. Valid Length ........... is-length()
/// 03. Valid Size ............. is-size()
/// 04. Decimal Round .......... decimal-round()
/// 05. Decimal Ceil ........... decimal-ceil()
/// 06. Decimal Floor .......... decimal-floor()
/// 07. Font Family ............ fontfamily-get()
/// 08. Font Weight ............ fontweight-get()
/// 09. PX to EM ............... em()
/// 10. PX to REM .............. rem()
/// 11. EM to PX ............... px()
/// 12. Map Fetch .............. map-fetch()
/// 13. Map Fetch Values ....... map-fetch-values()
/// 14. Key/Value Map .......... prop-value()
/// 15. Strip Units ............ strip-units()
/// 16. Text Inputs ............ text-inputs()
/// 17. Tilted Height .......... tilted-height()
/// 18. Calculeate brightness .. color-brightness()
/// 19. Color Contrast ......... color-contrast()
/// 20. Color .................. color()
/// ============================================================================
///
/// @group abstracts
////

/// Valid List
///
/// Verifica se `$list` contiente `$value`
///
/// @link http://goo.gl/yFrsto
///
/// @param {List} $list - Lista di valori.
/// @param {*} $value - Valore da verificare se è presente su list.
///
/// @example
///`@if contains(0 "auto" "initial" "inherit", 1) {
///     @debug "Contiene un valore valido";
/// } @else {
///     @debug "Non contiene un valore valido";
/// }
@function contains($list, $value) {
	@return not not index($list, $value);
}


/// Valid Length
///
/// Verifica se `$value` è un valore valido
/// Sono validi: 0 "auto" "initial" "inherit"
/// oppure un numero senza unità (px, em, etc.)
///
/// @link http://goo.gl/yFrsto
///
/// @param {*} $value - Valore da validare
///
/// @example
///`@if is-length(0) {
///     @debug "Contiene un valore valido";
/// } @else {
///     @debug "Non contiene un valore valido";
/// }
@function is-length($value) {
	@return contains(0 "auto" "initial" "inherit", $value)
            or type-of($value) == "number" and not unitless($value)
            or str-slice($value + "", 1, 4) == "calc";
}


/// Valid Size
///
/// Verifica se `$value` è una dimensione valida
/// Sono validi: 0 "fill" "fit-content" "min-content" "max-content"
/// oppure un numero con unità (1px, 1em, etc.)
///
/// @link http://goo.gl/yFrsto
///
/// @param {*} $value - Valore da validare
///
/// @requires {function} is-length
///
/// @example
///`@if is-size(10px) {
///     @debug "Contiene un valore valido";
/// } @else {
///     @debug "Non contiene un valore valido";
/// }
@function is-size($value) {
	@return is-length($value)
            or contains("fill" "fit-content" "min-content" "max-content", $value);
}


/// Decimal Round
///
/// Round di un numero specipico di decimali
///
/// @link https://goo.gl/yZdmXP
/// 
/// @param {Number} $number - A number to round
/// @param {Number} $digits [0] - Digits to output
/// @param {String} $mode [round] - (round|ceil|floor) How to round a number
///
/// @return {Number} A rounded number
/// 
/// @example
/// decimal-round(0.333)    => 0
/// decimal-round(0.333, 1) => 0.3
/// decimal-round(0.333, 2) => 0.33
/// decimal-round(0.666)    => 1
/// decimal-round(0.666, 1) => 0.7
/// decimal-round(0.666, 2) => 0.67
@function decimal-round (
	$number,
	$digits: 0,
	$mode: round
) {
	$n: 1;
	// $number must be a number
	@if type-of($number) != number {
		@warn '#{$number} is not a number.';
		@return $number;
	}
	// $digits must be a unitless number
	@if type-of($digits) != number {
		@warn '#{$digits} is not a number.';
		@return $number;
	} @else if not unitless($digits) {
		@warn '#{$digits} has a unit.';
		@return $number;
	}
	@for $i from 1 through $digits {
		$n: $n * 10;
	}
	@if $mode == round {
		@return round($number * $n) / $n;
	} @else if $mode == ceil {
		@return ceil($number * $n) / $n;
	} @else if $mode == floor {
		@return floor($number * $n) / $n;
	} @else {
		@warn '#{$mode} is undefined keyword.';
		@return $number;
	}
}


/// Decimal Ceil
///
/// Ceil di un numero specipico di decimali
///
/// @link https://goo.gl/yZdmXP
/// 
/// @param {Number} $number - A number to round
/// @param {Number} $digits [0] - Digits to output
///
/// @return {Number} A ceiled number
///
/// @example
/// decimal-ceil(0.333)    => 1
/// decimal-ceil(0.333, 1) => 0.4
/// decimal-ceil(0.333, 2) => 0.34
/// decimal-ceil(0.666)    => 1
/// decimal-ceil(0.666, 1) => 0.7
/// decimal-ceil(0.666, 2) => 0.67
@function decimal-ceil(
	$number,
	$digits: 0
) {
	@return decimal-round($number, $digits, ceil);
}


/// Decimal Floor
///
/// Floor di un numero specipico di decimali
///
/// @link https://goo.gl/yZdmXP
///
/// @param {Number} $number - A number to round
/// @param {Number} $digits [0] - Digits to output
///
/// @return {Number} A floored number
///
/// @example
/// decimal-floor(0.333)    => 0
/// decimal-floor(0.333, 1) => 0.3
/// decimal-floor(0.333, 2) => 0.33
/// decimal-floor(0.666)    => 0
/// decimal-floor(0.666, 1) => 0.6
/// decimal-floor(0.666, 2) => 0.66
@function decimal-floor(
	$number,
	$digits: 0
) {
	@return decimal-round($number, $digits, floor);
}


/// Font Family Get
///
/// @param {String} $key - Chiave della mappa $colors [es. gray]
/// @param {Number} $map [$colors] - La mappa di riferimento
///
/// @example scss - Usage
/// .foo {
///     font-family: fontfamily-get(body);
/// }
///
/// @example css - CSS Output
/// .foo {
///     font-family: Open Sans, Arial, Helvetica, sans-serif;
/// }
@function fontfamily-get(
	$key,
	$map: $fontfamily
) {
	@if contains(map-keys($map), $key) {
		$value: unquote(map-get($map, $key));

		@return $value
	} @else {
		@warn "Valore non valido! "#{$key}" non è presente nella mappa $fontfamily";

		@return null;
	}
}


/// Font Weight Get
///
/// @param {String} $key - Chiave della mappa $colors [es. gray]
/// @param {Number} $map [$colors] - La mappa di riferimento
///
/// @example scss - Usage
/// .foo {
///     font-weight: fontweight-get(regular);
/// }
///
/// @example css - CSS Output
/// .foo {
///     font-weight: 400;
/// }
@function fontweight-get(
	$key,
	$map: $fontweight
) {
	@if contains(map-keys($map), $key) {
		$value: map-get($map, $key);

		@return $value
	} @else {
		@warn "Valore non valido! "#{$key}" non è presente nella mappa $fontweight";

		@return null;
	}
}


/// PX to EM
///
/// @link https://goo.gl/r0tiqk
///
/// @parameter {Number} $target - Dimensione da convertire
/// @parameter {Number} $context [16px] - Contesto della conversione
///
/// @returns {number}
///
/// @example scss - Usage
/// .foo {
///     font-size: em(30);
/// }
/// .bar {
///     font-size: em(30px);
/// }
///
/// @example css - CSS Output
/// .foo {
///     font-size: 1.875em;
/// }
/// .bar {
///     font-size: 1.875em;
/// }
@function em(
	$target,
	$context: 16px
) {
	@if (unitless($target)) {
		$target: $target * 1px;
	}
	@if (unitless($context)) {
		$context: $context * 1px;
	}

	@return $target / $context * 1em;
}


/// PX to REM
///
/// @link https://goo.gl/r0tiqk
///
/// @parameter {Number} $target - Dimensione da convertire
/// @parameter {Number} $context [16px] - Contesto della conversione
///
/// @returns {number}
///
/// @example scss - Usage
/// .foo {
///     font-size: rem(30);
/// }
/// .bar {
///     font-size: rem(30px);
/// }
///
/// @example css - CSS Output
/// .foo {
///     font-size: 1.875rem;
/// }
/// .bar {
///     font-size: 1.87rem;
/// }
@function rem(
	$target,
	$context: 16px
) {
	@if (unitless($target)) {
		$target: $target * 1px;
	}
	@if (unitless($context)) {
		$context: $context * 1px;
	}

	@return $target / $context * 1rem;
}


/// EM to PX
///
/// @link https://goo.gl/r0tiqk
///
/// @parameter {Number} $target - Dimensione da convertire
/// @parameter {Number} $context [16px] - Contesto della conversione
///
/// @returns {number}
///
/// @example scss - Usage
/// .foo {
///     font-size: px(1);
/// }
/// .bar {
///     font-size: px(1em);
/// }
///
/// @example css - CSS Output
/// .foo {
///     font-size: 16px;
/// }
/// .bar {
///     font-size: 16px;
/// }
@function px(
	$target,
	$context: 16px
) {
	@if (unitless($target)) {
		$target: $target * 1em;
	}
	@if (unitless($context)) {
		$context: $context * 1em;
	}

	$target: $target / ($target * 0 + 1);
	$context: $context / ($context * 0 + 1);

	@return $target * $context + 0px;
}


/// Map Fetch
///
/// Un modo semplice per andare a prendere un valore profondo
/// in una mappa a più livelli. Funziona molto simile a map-get()
/// tranne che si passano più chiavi come secondo parametro.
///
/// @link http://goo.gl/wUYpzJ Hugo Giraudel
///
/// @param {String} $map - Il nome della mappa
/// @param {String} $keys - Il percorso e la sub-chiave desiderata
///
/// @example scss - Usage
/// $foo: map-fetch($breakpoints, root base);
@function map-fetch(
	$map,
	$keys
) {
	$key: nth($keys, 1);
	$length: length($keys);
	$value: map-get($map, $key);

	@if ($length > 1) {
		$rest: ();
		@for $i from 2 through $length {
			$rest: append($rest, nth($keys, $i));
		}

		@return map-fetch($value, $rest);
	} @else {
		@return $value;
	}
}


/// Map Fetch Values
///
/// Un modo semplice per andare a prendere un array di valori
/// profondi in una mappa a più livelli.
///
/// @param {String} $map - Il nome della mappa
/// @param {String} $values - Le chiavi desirate
///
/// @example scss - Usage
/// $foo: map-fetch-values($breakpoints, query);
@function map-fetch-values(
	$map,
	$values
) {
	$list: map-values($map);
	$rest: ();

	@each $value in $list {
		$rest: append($rest, map-get($value, $values), comma);
	}

	@return $rest;
}


/// Key/Value Map
///
/// Output each key/value in a map as css declarations
///
/// @param {map} $map - The map you wish to ouput
///
/// @example scss - Usage
/// $foo: prop-value($map);
@mixin prop-value($map) {
	@each $prop, $value in $map {
		#{$prop}: #{$value};
	}
}


/// Strip Units
///
/// Funzione che elimina l'unità valore (e.g. 12px => 12)
///
/// @link https://goo.gl/aO2Cvv Css Tricks
///
/// @param {lenght} $number - Numero con unità da eliminare
///
/// @example
/// $foo: strip-units(12px);
@function strip-units($number) {
	@if type-of($number) == 'number' and not unitless($number) {
		@return $number / ($number * 0 + 1);
	}

	@return $number;
}


/// Text Inputs
///
/// Genera un selettore di tutti gli imput type. È possibile filtrare
/// l'elenco per ottenere un sottoinsieme di elementi
///
/// @link https://goo.gl/N3CqXW Zurb Foundation for Sites
///
/// @param {List|Keyword} $types [()] - Elenco dei tipi di imput,
/// se lasciato vuoto utilizza tutti gli elementi.
///
/// @example
/// $foo: strip-units(12px);
@function text-inputs($types: ()) {
	$return: ();

	$all-types: text password date datetime datetime-local month week email number search tel time url color;

	@if not(contains($all-types, $types)) {
		$types: $all-types;
	}
	@each $type in $types {
		$return: append($return, unquote('[type="#{$type}"]'), comma);
	}

	@return $return;
}


/// Tilted Height
///
/// Computes the height of the tilted pseudo-element based on the given angle
/// using Pythagoras Theorem.
/// sin(..), pow(..) and sqrt(..)  functions come from this pen:
///
/// @param {Angle} $angle - the tilt angle
///
/// @link http://codepen.io/HugoGiraudel/pen/rLpPGo
/// @author Hugo Giraudel
@function tilted-height($angle) {
	$a: (100% / 1%);
	$A: (90deg - $angle);
	$c: ($a / sin($A));
	$b: sqrt(pow($c, 2) - pow($a, 2));

	@return (abs($b) * 1%);
}


/// Calculeate brightness
///
/// Calculeate brightness of a given color.
///
/// @param {lenght} $color - #000
///
/// @link https://codepen.io/bluesaunders/pen/FCLaz/
@function color-brightness($color) {
	@return ((red($color) * .299) + (green($color) * .587) + (blue($color) * .114)) / 255 * 100%;
}


/// Color Contrast
///
/// Compares contrast of a given color to the light/dark arguments and returns whichever is most "contrasty"
///
/// @param {lenght} $color - #000
///
/// @link https://codepen.io/bluesaunders/pen/FCLaz/
@function color-contrast($color, $dark: #000, $light: #fff) {
	@if $color == null {
		@return null;
	} @else {
		$color-brightness: color-brightness($color);
		$light-text-brightness: color-brightness($light);
		$dark-text-brightness: color-brightness($dark);

		@return if(abs($color-brightness - $light-text-brightness) > abs($color-brightness - $dark-text-brightness), $light, $dark);
	}
}


/// Color
///
/// @param {String} $color-name - Chiave della mappa $colors [es. gray]
/// @param {String} $color-variant - Sotto Chiave della mappa $colors [es. gray]
///
/// @link https://codepen.io/jakealbaugh/pen/zGGXwj
///
/// @example scss - Usage
/// .foo {
///     color: color(gray);
/// }
/// .bar {
///     color: color(primary, base);
/// }
@function color($color-name, $color-variant: null) {
	@if map-has-key($colors, $color-name) {
		@if ($color-variant != null) {
			@if map-fetch($colors, $color-name $color-variant) {
				// map inception
				@return map-get(map-get($colors, $color-name), $color-variant);
			}

			@warn "Il colore `#{$color-variant}` non è presente nella mappa $colors in `#{$color-name}`.";
		} @else {
			@return map-get($colors, $color-name);
		}
	}

	@warn "Il colore `#{$color-name}` non è presente nella mappa $colors.";
	@return null;
}