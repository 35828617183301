////
/// ============================================================================
/// SINGLE PRODUCT
///
/// 01. Layout
/// 02. Review
/// 03. Comments
/// 04. Respond Form
/// 05. Shop Attribute
/// 06. List
/// 07. Attachments
/// 08. Avatar
/// 09. Author Box
/// ============================================================================
///
/// @group pages
////

/**
 * Layout
 */
.single-product {
	&__body {
		img {
			margin-left: inherit;
		}
	}

	&__card {
		@include media(">=l") {
			display: none;
		}
	}
}

/**
 * Review
 */
#reviews {
	ul, ol {
		list-style: none;
	}

	.commentlist {
		margin: 0;
		padding: 0;

		li {
			margin-bottom: 1rem;
			padding-left: 0;

			p.meta {
				margin-top: 0.5rem;

				.verified {
					display: inline-block;
					height: 1em;
					position: relative;
					text-indent: -9999px;
					width: 1em;

					&:before {
						@extend %woocommerce-icon;
						color: color(success);
						content: "\e90d";
						height: 1em;
						left: 0;
						line-height: 1;
						position: absolute;
						text-align: center;
						text-indent: 0;
						top: 0;
						width: 1em;
					}
				}
			}
		}

		.comment_container {
			display: inline-block;
			position: relative;
			vertical-align: middle;
			width: 100%;

			img {
				border-radius: 60px;
				float: left;
				width: 60px;
			}
		}

		.comment-text {
			padding: 0 0 0 80px;

			.star-rating {
				float: right;
				margin-top: 0.5rem;
			}
		}
	}

	.woocommerce-Reviews-title {
		display: none;
	}
}


/**
 * Comments
 */
#comments {
	width: 100%;

	.comments-title {
		border-bottom: 1px solid color(gray-light);
		margin-bottom: 2.5rem;
		padding-bottom: 0.5rem;
	}

	.comment-list {
		list-style: none;
		margin: 0;
		padding-left: 0;

		.comment-body {
			@include clearfix;
		}

		.comment-content {
			margin-bottom: 2.5rem;
			padding-bottom: 2.5rem;

			.comment-text {
				border-radius: 3px;
				padding: 2.5rem;

				p:last-child {
					margin-bottom: 0;
				}
			}
		}

		.comment-meta {
			margin-bottom: 1em;

			.avatar {
				height: auto;
				margin-bottom: 1em;
				max-width: 64px;
			}

			a.comment-date {
				font-size: 12px;
			}

			cite {
				clear: both;
				display: block;
				font-style: normal;
			}
		}


		.reply {
			@include clearfix;
			margin-bottom: 2.5rem;
			padding-top: 0.5rem;

			a {
				display: inline-block;
				margin-right: 1em;

				&:last-child {
					margin-right: 0;
				}
			}
		}

		.children {
			list-style: none;
		}
	}
}

.comment-content a {
	word-wrap: break-word;
}


/**
 * Respond Form
 */
#respond {
	clear: both;
	position: relative;

	.comment-form-author,
	.comment-form-email,
	.comment-form-url {
		input {
			width: 100%;
		}
	}

	.form-submit,
	form {
		margin-bottom: 0;
	}

	.comment-reply-title {
		@include typography($h3);
		display: block;
		margin-bottom: vr(1, $map: $h3);
	}

	#cancel-comment-reply-link {
		@include absolute(top 1rem right 1rem);
		color: red;
		display: block;
		height: 1.5em;
		line-height: 1;
		overflow: hidden;
		text-align: center;
		text-decoration: none;
		width: 1.5em;

		&:hover {
			text-decoration: none;
		}

		&:before {
			content: "x";
			display: block;
			font-size: 1.5em;
		}
	}
}


/**
 * Shop Attribute
 *
 * Reset attributi tabella
 */
.woocommerce {
	.shop_attributes {
		display: block;
		margin-bottom: 0;

		th, td {
			background: transparent;
			border-bottom: 0;
			padding: 0.5rem;

			p {
				margin: 0;
			}
		}

		th {
			padding-right: 1em;
		}

		tbody tr:nth-child(2n) td {
			background: transparent;
			width: 100%;
		}
	}
}


/**
 * List
 */
.list {
	align-items: stretch;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	list-style: none;
	margin: 0;
	padding-left: 0;

	&__item {
		border: 1px solid color(gray-light);
		display: flex;
		margin-top: 1rem;
		padding: 1.5rem;
		width: 100%;

		&:first-child {
			margin-top: 0;
		}

		@include media(">=s") {
			width: 48%;
			&:nth-child(-n+2) {
				margin-top: 0;
			}
		}
	}

	&__icon {
		flex: 0 0 auto;
		height: 40px;
		width: 40px;
	}

	&__text {
		padding-left: 1em;
	}

	&--advantages {
		.list__icon {
			color: color(success);
		}
	}

	&--disadvantages {
		.list__icon {
			color: color(error);
		}
	}
}


/**
 * Attachments
 */
.attachments {
	list-style: none;
	margin: 0;
	padding-left: 0;

	&__item {
		border-top: 1px solid color(gray-light);
		padding: 1rem 0;

		&:first-of-type {
			border-top-style: none;
		}
	}
}

.attach {
	align-items: center;
	color: color(text, base);
	display: flex;
	justify-content: space-between;
	padding-bottom: 0.5rem;
	padding-top: 0.5rem;
	text-decoration: none;
	width: 100%;

	&:hover {
		text-decoration: none;
	}

	&__icon {
		flex-shrink: 0;
		height: 56px;
		width: 44px;

		svg {
			top: 0;
		}
	}

	&__title {
		margin-left: 1em;
		width: 100%;

		small {
			display: block;
			opacity: 0.8;
		}
	}
}


/**
 * Avatar
 */
.avatar img {
	border: 2px solid #fff;
	border-radius: 50%;
}


/**
 * Author Box
 */
.author-box {
	@include clearfix();
	background: color(gray-lighter);
	padding: 2rem;

	&:first-child {
		margin-top: 0;
	}

	&__avatar {
		margin-bottom: 2rem;
		@include media(">=s") {
			float: left;
			margin-bottom: 0;
		}
	}

	&__info {
		@include media(">=s") {
			margin-left: 150px;
			padding-left: 2em;
		}
	}

	&__name {
		margin-bottom: 0;
	}
}