////
/// ============================================================================
/// Advanced Custom Fields
/// ============================================================================
///
/// @group vendors
////

.acf-field input[type="text"],
.acf-field input[type="password"],
.acf-field input[type="number"],
.acf-field input[type="search"],
.acf-field input[type="email"],
.acf-field input[type="url"],
.acf-field textarea,
.acf-field select {
    font-size: inherit !important;
    line-height: inherit !important;
}

.acf-field p.description {
    font-size: 1rem !important;
}

.woocommerce-MyAccount-content #message {
    @extend .alert;
    margin-bottom: 2rem;
    &.updated {
        @extend .alert--success;
    }
}