////
/// ============================================================================
/// WP PLUGIN: KK Star
/// ============================================================================
///
/// @group vendors
////

.kk-star-ratings {
    margin-top: 1rem;

    .kksr-legend {
        font-size: 12px;
        margin: 0 0 0 0.5em;
        background: color(gray-light);
        border-radius: 50px;
        padding: 6px;
        text-align: center;
        display: flex;
    }
    .kksr-muted {
        opacity: 1;
        padding: 0 1px;
    }
}
