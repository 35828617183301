////
/// ============================================================================
/// HEADER
///
/// 01. Header
/// 02. Logo
/// 02. Nav
/// 03. Menu
/// ============================================================================
///
/// @group layout
////

/**
 * Header
 */
.header {
	&__topbar {
		background: color(gray-darker);
		color: color(gray);
		position: relative;
		z-index: 2;
	}

	&__headline {
		box-shadow: 0 0 10px rgba(color(gray-darker), 0.3);

		&-wrap {
			height: 70px;
			position: relative;
			@include media(">=l") {
				@include container();
				align-items: center;
				display: flex;
				height: 90px;

				justify-content: space-between;
				padding-left: gutter();
				padding-right: gutter();
			}
		}
	}

	&__logo {
		// Logo SVG Default Size
		$height: 50px;
		$width: 220px;

		@include center(left gutter(), $height / 1.5, $width / 1.5, 1);
		align-items: center;
		display: flex;

		@include media(">=l") {
			height: $height / 1.2;
			margin-right: 2%;
			margin-top: 0;
			position: static;
			width: $width / 1.2;
		}

		.logo {
			display: inline-flex;
			height: 100%;
			width: 100%;

			svg {
				top: 0;
			}
		}
	}

	&__navigation {
		@include media(">=l") {
			align-items: center;
			display: flex;
		}
	}

	.search-form {
		display: none;
		margin: 0;
		max-width: 100%;
		padding: 0;
		@include media(">=l") {
			display: block;
			&__query {
				font-size: 14px;
				padding: 0.5rem 1em;
				transition: width 0.5s;
				width: 120px;

				&:focus {
					width: 180px;
				}
			}
			&__button {
				width: 40px;
			}
			.btn {
				background: darken(color(gray-lighter), 10%);
				border-color: darken(color(gray-lighter), 10%);
			}
		}
	}
}


/**
 * Logo
 */
.logo {
	color: inherit;
	display: block;

	&:hover {
		color: inherit;
	}
}


/**
 * Topbar Nav
 *
 * @link https://codepen.io/andornagy/pen/xhiJH
 */
.topbar-nav {
	$topbar-nav--height: 44px;
	$topbar-nav--subnav-width: 180px;
	font-size: 14px;

	&, ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	&__item {
		display: inline-block;
		margin-right: 1em;
		@include media(">=l") {
			margin-right: 2em;
		}

		&:nth-child(2),
		&:nth-last-child(1),
		&:nth-last-child(2) {
			span {
				display: none;
				@include media(">=l") {
					display: inline-block;
				}
			}
		}

		&:nth-last-child(3) {
			margin-right: 0;
		}

		&--right {
			float: right;
		}
	}

	a {
		color: #a1a5a7;
		display: block;
		line-height: $topbar-nav--height;
		text-decoration: none;
	}

	ul {
		@include absolute(top $topbar-nav--height);
		@include hide(visuallyhidden);
		opacity: 0;
		transition: opacity 0.2s;

		&:after {
			@include triangle(#fff, 8px, 16px, top);
			@include absolute(top -8px left 1em);
			content: '';
		}
	}

	&__item:hover > ul {
		border: inherit; // reset visuallyhidden
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
		clip: inherit; // reset visuallyhidden
		height: inherit; // reset visuallyhidden
		margin: inherit; // reset visuallyhidden
		opacity: 1;
		overflow: inherit; // reset visuallyhidden
		padding: inherit; // reset visuallyhidden
		width: inherit; // reset visuallyhidden
	}

	ul li {
		background: #fff;
		display: list-item;
		float: none;
		min-width: $topbar-nav--subnav-width;
		position: relative;

		&:last-child {
			border-bottom-left-radius: $border-radius;
			border-bottom-right-radius: $border-radius;
		}

		a {
			color: color(text, base);
			padding-left: 1em;
			padding-right: 1em;
			transition: background 0.2s;

			&:hover {
				background: color(gray-lighter);
			}
		}
	}

	ul ul li {
		@include relative(top (-$topbar-nav--height) left $topbar-nav--subnav-width);
	}

	//li > a:after {
	//    content: ' +';
	//}
	//li > a:only-child:after {
	//    content: ' ';
	//}

	svg {
		height: 24px;
		width: 24px;
	}
}


/**
 * Nav
 */
.nav {
	height: 70px;
	position: relative;
	width: 100%;

	@include media(">=l") {
		height: 90px;
	}

	// Main Nav
	.main-nav {
		position: relative;
		width: 100%;
	}
}


/**
Mega Menu
 */
#mega-menu-wrap-header {
	#mega-menu-header {
		& > li.mega-menu-item {
			@include media("<l") {
				background: #fff;
				border-top: 1px solid color(gray);
			}

			&.is-new > a.mega-menu-link {
				&::after {
					background: color(secondary, light);
					border-radius: 10px;
					color: #fff;
					content: 'new';
					display: inline-block;
					font-size: 11px;
					letter-spacing: 1px;
					line-height: 18px;
					margin-left: 5px;
					padding: 0 8px;
					position: relative;
					text-align: center;
					text-transform: uppercase;
					top: -3px;
					@include media(">=l") {
						float: right;
						top: 50%;
						transform: translateY(-50%);
					}
				}
			}

			& > a.mega-menu-link {
				@include media("<l") {
					padding: 0 gutter() !important;
				}
			}
		}

		& > li.mega-menu-flyout ul.mega-sub-menu li.mega-menu-item a.mega-menu-link {
			padding: 0 gutter() !important;
		}
	}

	.mega-menu-toggle {
		margin-right: gutter();

		.mega-toggle-blocks-right .mega-toggle-block {
			margin-right: 0;
		}
	}
}