////
/// ============================================================================
/// WOOCOMMERCE NOTIFY
/// ============================================================================
///
/// @group woocommerce
///

.woocommerce-error,
.woocommerce-info,
.woocommerce-message,
.woocommerce-noreviews,
p.no-comments,
.woocommerce-notice {
    @include clearfix();
    background-color: color(success);
    border-left: 0.5rem solid rgba(0, 0, 0, .15);
    clear: both;
    color: #fff;
    margin-bottom: 2.5rem;
    margin-left: 0;
    padding: 1em 1.618em;
    width: 100%; // Fix for display flex
    a {
        color: #fff;
        &:hover {
            color: #fff;
            opacity: .7;
        }
        &.button:hover {
            opacity: 1;
        }
    }
    .button {
        background: 0 0;
        border-width: 0;
        border-left-width: 1px;
        border-left-style: solid;
        border-left-color: rgba(255, 255, 255, .25) !important;
        border-radius: 0;
        box-shadow: none;
        color: #fff;
        float: right;
        line-height: 1.618;
        padding: 0 0 0 1em;
        &:hover {
            background: 0 0;
            color: #fff;
            opacity: 0.7;
        }
    }
    pre {
        background-color: rgba(0, 0, 0, .1);
    }
    li {
        background: transparent;
    }
}

.woocommerce-error {
    list-style: none;
}

.woocommerce-info,
.woocommerce-noreviews,
p.no-comments {
    background-color: color(info);
}

.woocommerce-error {
    background-color: color(error);
}

.woocommerce-notice--success {
    background-color: color(success);
}