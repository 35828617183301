////
/// ============================================================================
/// BASE
///
/// 01. Body
/// 02. Layout
/// 03. VC Layout
/// ============================================================================
///
/// @group layout
////

html {
    scroll-behavior: smooth;
}


/**
 * Body
 */
body {
    @include debug(breakpoints);
    background: color(body);
    color: color(text, base);
}


/**
 * Layout
 */
.l {
    &-container {}
    &-wrapper {
        //@include debug(baseline);
        @include container;
        @include clearfix;
        @include show-grid;
        padding-left: gutter();
        padding-right: gutter();
        position: relative;
        z-index: 1;
    }
    &-main {
        @include clearfix;
        &__header {
            background: color(gray-darker);
            color: #fff;
            position: relative;
            text-align: center;
            overflow: hidden;
        }

        &__title {
            position: relative;
            @include media(">=l") {
                @include squish(1, 1);
            }
        }

        &__content {
            align-items: stretch;
            display: flex;
            flex-wrap: wrap;
            &--onlytext {
                @include media(">=l") {
                    margin-left: auto;
                    margin-right: auto;
                    width: span(8);
                }
            }
        }

        &__left,
        &__right {
            position: relative;
            width: 100%;
        }
        &__left {
            @include media(">=l") {
                //border-right: 1px solid color(gray-light);
                float: left;
                padding-right: gutter(12);
                width: span(8.5) + gutter(12);
            }
        }
        &__right {
            @include media(">=l") {
                //border-left: 1px solid color(gray-light);
                float: right;
                margin-left: -1px;
                margin-right: 1px;
                padding-left: gutter();
                width: span(3.5);
            }
        }
        &__center {
            @include media(">=l") {
                margin-left: auto;
                margin-right: auto;
                width: span(7) + gutter(12);
            }
        }
    }
}


/**
 * Utility
 */
.u {
    &-leading {
        &-s {
            padding-top: 2rem;
        }
        &-m {
            padding-top: 4rem;
        }
        &-l {
            padding-top: 6rem;
        }
        &-xl {
            padding-top: 8rem;
        }
    }

    &-trailing {
        &-s {
            padding-bottom: 2rem;
        }
        &-m {
            padding-bottom: 4rem;
        }
        &-l {
            padding-bottom: 6rem;
        }
        &-xl {
            padding-bottom: 8rem;
        }
    }

    /* Aspect Ratio */
    &-ratio {
        position: relative;
        > * {
            @include absolute(top 0 left 0, 1);
            height: 100%;
            width: 100%;
        }
        &--book {
            @include aspect-ratio(55 78);
        }
        &--11 {
            @include aspect-ratio(1 1);
        }
        &--169,
        &--hd {
            @include aspect-ratio(16 9);
        }
        &--wide {
            @include aspect-ratio(2.39 1);
        }
        &--tv {
            @include aspect-ratio(4 3);
        }
        &--imax {
            @include aspect-ratio(1.43 1);
        }
    }

    /* Background */
    &-bg {
        &-primary {
            background-color: color(primary, base);
            color: #fff;
        }
        &-graylight {
            background-color: color(gray-lighter);
        }
        &-graydark {
            background-color: color(gray-darker);
            color: #fff;
        }
        &-cover {
            @include absolute(top 0 right 0 bottom 0 left 0, 0);
            height: 100%;
            overflow: hidden;
            width: 100%;
        }
    }
}


/**
 * Section
 */
.section {
    @include clearfix();
    width: 100%;
    &__header {
        position: relative;
        width: 100%;
        h2 {
            color: color(primary, base);
            margin-bottom: 0;
            text-transform: uppercase;
        }
    }
    &__content {
        @include clearfix();
        position: relative;
        width: 100%;
        & > p,
        & > ul,
        & > ol {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}