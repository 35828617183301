////
/// ============================================================================
/// TABS
/// ============================================================================
///
/// @link https://codepen.io/mrtrimble/pen/pEZJLY Ispiration
///
/// @group components
////

.tabs {
    @include clearfix();
    position: relative;
    width: 100%;
    &__label {
        @include typography($h4);
        align-items: center;
        box-shadow: inset 0 -1px 0 color(gray-light);
        display: flex;
        color: color(gray);
        cursor: pointer;
        float: left;
        margin: 0;
        justify-content: center;
        padding: 1.5rem 1em;
        text-align: center;
        text-decoration: none;
        width: (100% / 3);

        &:hover {
            color: color(gray);
            text-decoration: none;
        }

        span {
            font-weight: fontweight-get(bold);
            overflow: hidden;
            text-overflow: ellipsis;
            text-transform: uppercase;
            white-space: nowrap;
            width: auto;
        }
        svg {
            display: inline-block;
            height: 25px;
            width: 25px;
            @include media(">=s") {
                margin-right: 0.5em;
            }
        }
        .short {
            display: inline-block;
            @include media(">=m") {
                display: none;
            }
        }
        .long {
            display: none;
            @include media(">=m") {
                display: inline-block;
            }
        }
    }
    &__content {
        @include clearfix();
        box-shadow: 0 -1px 0 color(gray-light);
        clear: both;
        display: none;
        padding-top: 2rem;
    }

    &--four {
        .tabs__label {
            width: (100% / 4);
        }
    }

    #tab1:checked ~ #tab-content1,
    #tab2:checked ~ #tab-content2,
    #tab3:checked ~ #tab-content3,
    #tab4:checked ~ #tab-content4 {
        display: block;
    }
    & [id^="tab"]:checked + .tabs__label,
    .tabs__label--active {
        box-shadow: inset 0 6px 0 color(primary, base), inset 1px 0 0 color(gray-light), inset 0 -1px 0 #fff, inset -1px 0 0 color(gray-light);
    }

    &--links {
        box-shadow: inset 0 -1px 0 color(gray-light);
    }
}