////
/// ============================================================================
/// SIDEBAR
///
/// 01. Sidebar
/// 02. Submenu
/// 03. Ads
/// 04. Most viewed
/// 05. Table of Contents
/// ============================================================================
///
/// @group layout
////

/**
 * Sidebar
 */
.sidebar {
    /* Sticky element */
    &.is_stuck {
        padding-left: 0;
    }

    &-product {
        display: none;
        @include media(">=l") {
            display: block;
        }
    }


    div.woocommerce button.alt {
        background-color: color(secondary, base);
        border-color: color(secondary, base);
        color: #fff;
        &:hover {
            background-color: color(secondary, light);
            border-color: color(secondary, light);
            color: #fff;
        }
    }
}


/**
 * Submenu
 */
.submenu {
    &__item {
        border-bottom: 1px solid color(gray-light);

        &--open {
            .submenu__accordion {
                transform: rotate(90deg);
            }
        }
        &--parent,
        &--active {
            > .submenu__link {
                color: color(primary, base);
            }
        }
    }
    &__dropdown {}
    &__link {
        color: color(text, base);
        cursor: pointer;
        display: block;
        padding: 0.5rem 1em;
        position: relative;
    }
    &__accordion {
        @include center(right 0.5em, 24px, 24px, 1);
        cursor: pointer;
        transition: all 0.5s;
    }
    &__sub {
        display: none;
        margin: 1rem 1em;
        .submenu__item {
            border-left: 4px solid color(gray-lighter);
            border-bottom: none;
            &--active {
                border-left-color: color(primary, base);
                .submenu__link {
                    background: none;
                    color: color(text, base);
                }
            }
        }
        .submenu__link {
            padding: 0.2rem 1em;
        }
    }
}


/**
 * Ads
 */
.sidebar-ads {
    text-align: center;
}


/**
 * Most viewed
 */
.sidebar-mostviewed {
    &__header {
        h3 {
            margin-top: 0;
        }
    }

    &__content {
        .entry {
            margin: 0;
            padding-bottom: 2rem;
            @include media(">=s") {
                @include span(6);
                &:nth-child(2n) {
                    @include last();
                }
            }
            @include media(">=l") {
                padding-bottom: 20px;
                &, &:nth-child(2n) {
                    float: none;
                    margin-right: 0;
                    width: 100%;
                }
                &__meta {
                    display: none
                }
            }
            figure {
                margin-left: 1px;
            }
        }
    }
    &__wrapper {
        box-sizing: inherit;
        flex-wrap: wrap;
        @include media(">=l") {
            flex-wrap: nowrap;
        }
    }
    &__pagination {
        display: none;
        @include media(">=l") {
            display: block;
        }
    }
}