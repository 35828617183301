////
/// ============================================================================
/// WOOCOMMERCE CART
/// ============================================================================
///
/// @group woocommerce
///

/**
 * Products List
 */
ul.products {
	@include clearfix();
	margin: 0;
	padding-left: 0;
	li.product {
		list-style: none;
		margin-bottom: 4.235801032em;
		margin-left: 0;
		position: relative;
		text-align: center;
		@include media(">=m") {
			clear: none;
			width: 100%;
			float: left;
			font-size: .875em;
		}
		.star-rating {
			margin: 0 auto .5407911001em;
		}
		.woocommerce-LoopProduct-link {
			display: block;
		}
		.price {
			display: block;
			color: #43454b;
			font-weight: 400;
			margin-bottom: 1rem;
			ins {
				margin-left: .6180469716em;
				background-color: transparent;
			}
		}
		.woocommerce-loop-product__title,
		h2,
		h3 {
			font-size: 1rem;
			font-weight: 400;
			margin-bottom: .5407911001em;
		}
		.star-rating {
			font-size: .875em;
		}
		img {
			display: block;
			margin: 0 auto 1.618em;
		}
		&.product-category {
			.woocommerce-loop-category__title,
			h2,
			h3 {
				font-size: 1.1em;
			}
			img {
				margin-bottom: 1.618em;
			}
		}
		.button {
			margin-bottom: .236em;
		}
	}
}


/**
 * Collaterals
 */
.cart-collaterals {
	@include clearfix();
	clear: both;
	h2 {
		@include typography($h3);
		width: 100%;
	}
	p {
		margin-top: 0;
		width: 100%;
	}
	.cross-sells {
		@include media(">=m") {
			@include span(6);
		}
	}
	.cart_totals,
	.shipping_calculator {
		@include media(">=m") {
			@include span(last 6);
		}
	}
	.cart_totals {
		small.includes_tax {
			display: block
		}
	}
}


/**
 * CTA Proceed to Checkout
 */
.wc-proceed-to-checkout {
	@include clearfix();
	margin-bottom: 1.5rem;
	.button {
		display: block !important;
		margin-bottom: 1em;
		text-align: center;
		&.checkout-button {
			background: color(secondary, base);
			border-color: color(secondary, base);
			font-size: 1.5rem;
			padding-bottom: 1rem;
			padding-top: 1rem;
		}
	}
}

/**
 * Shipping Method
 */
ul#shipping_method {
	list-style: none;
	padding-left: 0;
	margin: 0;

	li {
		display: flex;
		margin-bottom: 1rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	input[type="checkbox"],
	input[type="radio"] {
		margin-top: 0.3em;
	}

	label {
		display: inline-block;
		vertical-align: baseline;
		margin-left: 0.5rem;
		margin-right: 1rem;
		margin-bottom: 0;
		line-height: 1.5;
	}

	.woocommerce-checkout & {
		margin-bottom: 0;
	}

	& + p {
		border-top: 1px solid color(gray-light);
		margin-top: 1rem;
		padding-top: 1rem;
	}
}


/**
 * Amazon Pay
 */
#pay_with_amazon {
	background-color: #ffc439;
	background-image: url("images/amazonpay.svg");
	background-repeat: no-repeat;
	border-radius: $border-radius;
	border: 0;
	cursor: pointer;
	display: block!important;
	float: none!important;
	font-size: 0;
	height: 38px;
	margin: 0 auto!important;
	overflow: hidden;
	position: relative;
	max-width: 200px;
	width: 100%;
	img {
		opacity: 0;
	}
}

.wc-amazon-checkout-message {
	display: none;
}


/**
 * Free Shipping Notice
 */
.free-shipping {
	background-color: color(gray-lighter);
	border: 1px solid color(gray-light);
	display: flex;
	flex-direction: column;
	margin-bottom: 3rem;
	padding-bottom: 2em;
	padding-top: 2em;
	text-align: center;
	@include media(">=m") {
		align-items: center;
		background-image: url("images/bg-freeshipping.jpg");
		background-position: 50% 50%;
		background-repeat: no-repeat;
		flex-direction: row;
		justify-content: space-between;
		padding-bottom: 3.5em;
		padding-top: 3.5em;
	}
	&__title {
		margin-bottom: 0;
		padding-left: 2rem;
		padding-right: 2rem;
		width: 100%;
		@include media(">=m") {
			padding-right: 0;
			text-align: left;
			width: (100% / 3);
		}
		@include media(">=xl") {
			padding-left: 3rem;
		}
	}
	&__image {
		background-image: url("images/bg-freeshipping.jpg");
		background-position: 50% 50%;
		background-repeat: no-repeat;
		height: 320px;
		@include media(">=m") {
			display: none;
		}
	}
	&__books {
		padding-left: 2rem;
		padding-right: 2rem;
		width: 100%;
		@include media(">=m") {
			padding-left: 0;
			width: (100% / 3);
		}
		@include media(">=xl") {
			padding-right: 3rem;
		}
		h3 {
			font-weight: fontweight-get(bold);
			margin: 0;
			span {
				color: red;
				font-weight: bold;
				font-size: 0.5em;
				vertical-align: top;
			}
		}
		ul {
			@include typography($h4);
			display: inline-block;
			list-style: none;
			margin-bottom: 0;
			padding-left: 0;
			li {
				border-bottom: 1px solid color(gray-light);
				padding: 0.5rem 1em;
				&:last-child {
					border-bottom: none;
				}
			}
		}
	}
	&__help {
		color: red;
		font-size: 0.9em;
		line-height: normal;
		margin-bottom: 0;
		margin-top: 1em;
		padding: 0 5%;
	}
}