////
/// ============================================================================
/// ARCHIVE
///
/// 01. Layout
/// 02. Archive List
/// 03. Magazine
/// 04. Catalogo
/// ============================================================================
///
/// @group pages
////

/**
 * Layout
 */
.archive {
	&__wrap {
		width: 100%;
	}

	&__content {
	}
}

/**
 * Entries
 */
.archive-entries {
	align-items: stretch;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;

	// 2 Columns
	&--lines {
		@include media(">=l") {
			.entries {
				border-left: 1px solid color(gray-light);
				padding-left: gutter();
				padding-right: gutter();
				$gutters: gutter() * 2;

				&:first-child {
					border-left: none;
					padding-left: 0;
					width: ((100% - $gutters) / 2) + gutter();
				}

				&:last-child {
					padding-right: 0;
					width: ((100% - $gutters) / 2) + gutter();
				}
			}
		}

		.entries {
			@include media(">=m") {
				width: span(4.25 of 8.5);
				&:nth-child(-n+2) {
					margin-top: 0;
				}
			}
		}
	}

	// 3 Columns
	&--three {
		.entries {
			@include media(">=m") {
				width: span(6);
				&:nth-child(-n+2) {
					margin-top: 0;
				}
			}
			@include media(">=l") {
				width: span(4);
				&:nth-child(-n+3) {
					margin-top: 0;
				}
			}
		}

		&.archive-entries--lines {
			@include media(">=l") {
				.entries {
					border-left: 1px solid color(gray-light);
					padding-left: gutter();
					padding-right: gutter();
					$gutters: gutter() * 4;
					width: ((100% - $gutters) / 3) + (gutter() * 2);

					&:first-child {
						border-left: none;
						padding-left: 0;
						width: ((100% - $gutters) / 3) + gutter();
					}

					&:last-child {
						padding-right: 0;
						width: ((100% - $gutters) / 3) + gutter();
					}
				}
			}
		}
	}

	&--home {
		.entries--large {
			@include media(">=m") {
				background: color(gray-lighter);
				padding: 1.5rem;
				.entry {
					&__title {
						font-size: 1.75em;
						line-height: 1.2;
					}
				}
			}
			@include media(">=l") {
				padding: 2.5rem;
				width: span(6);
			}
		}

		.entries--medium {
			@include media(">=l") {
				padding: 2.5rem 0;
				width: span(3);
			}
		}
	}

	&--lessons {
		.entries {
			align-items: stretch;
			display: flex;
			flex-wrap: wrap;
			width: 100%;
		}

		.entry {
			@include media(">=s") {
				@include span(6);
				&:nth-child(2n) {
					margin-right: 0;
				}
				&:nth-child(-n+2) {
					margin-top: 0;
				}
			}
			@include media(">=m") {
				width: span(4);
				&,
				&:nth-child(2n) {
					margin-right: gutter();
				}
				&:nth-child(3n) {
					margin-right: 0;
				}
				&:nth-child(-n+3) {
					margin-top: 0;
				}
			}
			@include media(">=l") {
				width: span(3);
				&,
				&:nth-child(3n) {
					margin-right: gutter();
				}
				&:nth-child(4n) {
					margin-right: 0;
				}
				&:nth-child(-n+4) {
					margin-top: 0;
				}
			}
		}
	}
}

.entries {
	margin-top: 4rem;
	width: 100%;

	&:first-child {
		margin-top: 0;
	}

	.entry {
		height: auto;
	}

	&--large {
		.entry__excerpt {
			display: none;
		}

		@include media(">=m") {
			.entry {
				&__title {
					font-size: 1.6em;
					line-height: 1.15;
					margin-top: 1rem;
				}

				&__excerpt {
					display: block;
				}
			}
		}
	}

	&--large,
	&--medium {
		@include media(">=m") {
			.entry {
				display: block;
				flex-wrap: inherit;
				justify-content: inherit;

				&__image {
					display: block;
					margin-bottom: 1rem;
					width: 100%;
				}

				&__content {
					width: 100%;
				}
			}
		}
	}

	&--list {
		ul {
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				border-bottom: 1px solid color(gray-light);

				&:last-child {
					border-bottom: 0;
				}
			}

			a {
				align-items: center;
				color: color(text, base);
				display: flex;
				padding: 0.5rem 0;
				text-decoration: none;

				&:hover {
					color: $link-hover-color;
				}
			}

			svg {
				color: color(gray);
				height: 24px;
				margin-right: 0.5em;
				width: 24px;
			}
		}
	}
}

/**
 * Archive List
 */
.archive-list {
	@include clearfix();
	width: 100%;

	&__header {
		text-align: center;
		@include media(">=m") {
			text-align: left;
		}

		&--actions {
			@include media(">=m") {
				align-items: center;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
			}

			.btn {
				display: table;
				margin: 1rem auto 0 auto;
				@include media(">=m") {
					margin: 0;
				}
			}
		}

		&--magazine {
			border-top: 1px solid color(gray-light);
			margin-top: 4rem;
			padding-top: 2rem;

			h2 {
				color: color(text, base);
			}

			@include media(">=l") {
				margin-top: 6rem;
				padding-top: 4rem;
			}
		}
	}

	&__content {
		@include clearfix();
		width: 100%;
	}

	.entry {
		height: auto; // Fix swiper-slide class
	}

	/**
	 * Blog
	 */
	&--blog {
		.entry {
			border-bottom: 1px solid color(gray-light);
			flex-wrap: wrap;
			justify-content: flex-start;
			margin-bottom: 2rem;
			padding-bottom: 2rem;
			@include media(">=s") {
				flex-wrap: nowrap;
				justify-content: space-between;
			}

			&:last-child {
				border-bottom: none;
				margin-bottom: 0;
				padding-bottom: 0;
			}

			&__title {
				@include typography($h3);
			}

			&__image {
				margin-bottom: 2rem;
				width: 100%;
				@include media(">=s") {
					@include span(5);
					margin-bottom: 0;
				}
				@include media(">=l") {
					margin-right: gutter(8.5);
					width: span(3 of 8.5);
				}
			}

			&__content {
				width: 100%;
				@include media(">=s") {
					@include span(7);
				}
				@include media(">=l") {
					margin-right: gutter(8.5);
					width: span(5.5 of 8.5);
				}
			}
		}
	}

	/**
	 * Product Default
	 *
	 * Book, Magazine, Certificati
	 */
	&--product {
		.archive-list__content {
			.entry {
				@include media(">=l") {
					@include span(6);
					&:nth-of-type(2n) {
						margin-right: 0;
					}
				}
			}
		}
	}

	/**
	 * Carousel
	 */
	&--carousel {
		.entry {
			&__image,
			&__content {
				padding: 0;
				text-align: center;
				width: 100%;
			}

			.star-rating,
			&__subtitle,
			&__cart {
				display: none;
			}

			&__title {
				@include typography($h4);
				margin-top: 2rem;
			}

			@include media(">=s") {
				width: span(6);
			}
			@include media(">=m") {
				width: span(4);
			}
			@include media(">=xl") {
				width: span(3);
			}
		}
	}

	/**
	 * Shop
	 */
	&--shop {
		align-items: stretch;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
}

/**
 * Magazine
 */
.magazine-subscription {
	position: relative;
	width: 100%;
	@include media(">=m") {
		align-items: center;
		display: flex;
	}

	&__cover {
		@include aspect-ratio(8 10);
		align-items: center;
		display: flex;
		flex-flow: row wrap;
		position: relative;
		text-align: center;
		width: 100%;
		@include media(">=m") {
			@include span(6);
			margin-bottom: 0;
			order: -1;
		}

		.mag-cover {
			width: 50%;

			&__wrap {
				display: inline-block;
				position: relative;

				&::before {
					@include absolute(bottom 0 left 1.5em right 1.5em, 0);
					box-shadow: 0 20px 30px -5px rgba(0, 0, 0, .2);
					content: '';
					display: block;
					height: 30px;
				}
			}

			&--0 {
				min-width: 100%;
				position: absolute;
				text-align: center;
				z-index: 1;

				.mag-cover__wrap {
					margin: auto;
					max-width: 480px;
					width: 80%;
				}

				@include media(">=s") {
					img {
						width: 480px;
					}
				}
			}

			&--1 {
				text-align: left;
			}

			&--2 {
				text-align: right;
			}

			&--1,
			&--2 {
				img {
					filter: grayscale(50%) blur(3px);
				}
			}
		}
	}

	&__content {
		@include media(">=m") {
			@include span(last 6);
			@include prefix(0.5);
		}

		h2 {
			@include typography($h1);
			font-weight: fontweight-get(bold);
			margin-bottom: 2rem;
		}

		h3 {
			@include typography($m);
			color: color(text, light);
			font-weight: fontweight-get(regular);
			margin-bottom: vr(1.5, $map: $m);
			margin-top: vr(0.5, $map: $m);
		}

		ul {
			@include typography($m);
			list-style: none;
			padding-left: 0;

			li {
				border-bottom: 1px solid color(gray-light);
				padding-bottom: 1rem;
				padding-top: 1rem;

				&:last-child {
					border-bottom: none;
				}
			}
		}

		small {
			color: color(text, light);
			display: block;
			font-size: 15px;
			line-height: 20px;
			margin-top: 2rem;
		}
	}

	&__cta {
		background: color(secondary, base);
		border-color: color(secondary, base);
		color: #fff;
		font-size: 1.5rem;
		padding-bottom: 2rem;
		padding-top: 2rem;
		text-overflow: inherit;
		white-space: normal;

		&:hover {
			background: color(secondary, light);
			border-color: color(secondary, light);
			color: #fff;
		}

		del {
			font-size: 18px;
			font-weight: 400;
			padding: 0 0.2em;
			position: relative;
			top: -1px;
		}
	}
}

/**
 * Catalogo
 */
.catalog {
	margin-bottom: 2rem;
	position: relative;
	text-align: center;
	width: 100%;
	@include media(">=s") {
		width: span(6);
	}
	@include media(">=l") {
		width: span(4);
	}

	&__link {
		color: #fff;

		&:hover {
			text-decoration: none;

			.catalog__image:before {
				background: rgba(color(primary, base), 0.8);
				border-width: 0;
			}
		}
	}

	&__title {
		@include typography($h4);
		@include transform(null, 1);
		color: #fff;
		text-transform: uppercase;
	}

	&__image {
		height: 100%;
		position: absolute;
		width: 100%;
		z-index: 0;

		&:before {
			@include absolute(top -1px right -1px bottom -1px left -1px, 1);
			background: rgba(#000, 0.4);
			border: 1rem solid #fff;
			content: '';
			display: block;
			transition: all 0.3s;
			transition-timing-function: ease-in;
			transition-timing-function: cubic-bezier(0.42, 0, 1, 1);
		}

		picture {
			filter: grayscale(0.9) saturate(0.8);
		}
	}
}

/**
 * Archive Navigation
 */
.archive-nav {
	border: 1px solid color(gray-light);
	min-height: 20rem;
	padding: 0 3em;
	position: relative;
	width: 100%;
	z-index: 99;
	@include media(">=m") {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
	}

	&__content {
		margin: 2rem auto;
		max-width: 400px;
		position: relative;
		z-index: 2;
		@include media(">=m") {
			align-items: center;
			display: flex;
			flex-wrap: wrap;
			margin-left: 340px;
			margin-right: 0;
			max-width: none;
		}

		p {
			margin-top: 0;
		}

		.search-form,
		.select-menu {
			flex: 1 0 auto;
			margin: 0.5rem 0;
		}

		.search-form {
			@include media(">=m") {
				margin-right: 1em;
			}
		}
	}

	&__image {
		margin: auto;
		position: relative;
		text-align: center;
		z-index: 1;
		@include media(">=m") {
			@include absolute(bottom 0, left 2rem);
			margin-top: -2rem;
		}
	}
}


/**
 * Lesson Navigation
 */
.lesson-nav {
	width: 100%;

	&__list {
		align-items: stretch;
		border-top: 1px solid color(gray-light);
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		@include media(">=m") {
			flex-wrap: nowrap;
		}
	}

	&__item {
		flex: 0 1 100%;

		a {
			align-items: center;
			color: color(text, base);
			display: flex;
			justify-content: center;
			padding: 1rem;

			&:hover {
				color: color(primary, base);
			}

			@include media(">=m") {
				padding: 2rem;
			}
		}

		&--category {
			@include media(">=m") {
				flex-basis: 60%;
			}

			span {
				display: none;
			}
		}

		&--prev {
			a {
				justify-content: flex-start;
			}
		}

		&--next {
			a {
				justify-content: flex-end;
			}
		}

		svg {
			height: 38px;
			margin: 0.5em;
			width: 38px;
		}
	}
}