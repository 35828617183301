////
/// ============================================================================
/// BUTTONS
/// ============================================================================
///
/// @link http://unicorn-ui.com/buttons/ Ispiration
///
/// @group components
////

.added_to_cart,
.button {
    @extend .btn;

    &,
    &.add_to_cart_button,
    &.wc-forward,
    &.cta,
    &.alt {
        background-color: color(secondary, base);
        border-color: color(secondary, base);
        color: #fff;
        &:hover {
            background-color: color(secondary, dark);
            border-color: color(secondary, dark);
            color: #fff;
        }
    }
    &.loading {
        position: relative;
        &:after {
            @include center(center, 20px, 20px);
            @extend %woocommerce-icon;
            animation: wc-icon-spin 0.75s linear infinite;
            content: "\e90c";
            font-size: 20px;
            line-height: 20px;
            opacity: 0.5;
        }
    }
    &.small {
        padding: 0.5em 1em;
        font-size: .85em;
    }
    &.disabled,
    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        &:hover {
            opacity: 0.5;
        }
    }
}