////
/// ============================================================================
/// FACEBOOK
/// ============================================================================
///
/// @group vendors
////

.fb-comments {
    span iframe {
        width: 100%!important;
    }
}
