////
/// ============================================================================
/// TYPOGRAPHY
///
/// 01. Mixin Type Breakpoint ................ _type-breakpoint()
/// 02. Mixin Type write props ............... _type-write-props()
/// 03. Function Type font size .............. _type-font-size()
/// 04. Function Type calc font size ......... _type-calc-font-size()
/// 05. Function Type base size .............. _type-base-size()
/// 06. Mixin Typography ..................... typography()
/// 07. Mixin Typography Base ................ typography-base()
/// 08. Function: Vertical rhythm ............ vr()
/// 09. Mixin: Vertical rhythm ............... vr()
/// ============================================================================
///
/// @link https://github.com/zellwk/typi
///
/// @group abstracts
////

$breakpoints: () !default;
$typography: (null: (16px, 1.5)) !default;


/// Mixin Type Breakpoint
///
/// Utilizza breakpoint-sass
///
/// @param {length} $typography-map - $h1 | $h2 | $404 | etc..
@mixin _type-breakpoint(
    $typography-map
) {
    $breakpoint: map-get($typography-map, breakpoint);
    $breakpoints: map-get($typography-map, breakpoints);

    $point: map-get($breakpoints, $breakpoint);

    @include media(">=#{$point}") {
        @include _type-write-props($typography-map);
    }
}


/// Mixin Type write props
///
/// Scrive le proprietà di font-size (e line height)
///
/// @param {length} $typography-map - $h1 | $h2 | $404 | etc..
@mixin _type-write-props (
    $typography-map
) {
    $target: map-get($typography-map, target);
    $breakpoint: map-get($typography-map, breakpoint);
    $typography: map-get($typography-map, typography);
    $base: map-get($typography-map, base);
    $rem: map-get($typography-map, rem);
    
    $bp-val: map-get($target, $breakpoint);
    $font-size: null;
    $line-height: null;

    @if type-of($bp-val) == "list" and length($bp-val) > 1 {
        $font-size: nth($bp-val, 1);
        $line-height: nth($bp-val, 2);
        font-size: _type-font-size($font-size, $breakpoint, $typography, $rem, $base);
        line-height: $line-height;
    }
    @else {
        $font-size: $bp-val;
        font-size: _type-font-size($font-size, $breakpoint, $typography, $rem, $base);
    }
}


/// Function Type font size
///
/// Richiama _type-calc-font-size o _type-base-size
///
/// @param {length} $font-size
/// @param {length} $breakpoint
/// @param {length} $typography
/// @param {length} $rem
/// @param {length} $base
@function _type-font-size(
   $font-size,
   $breakpoint,
   $typography,
   $rem,
   $base
) {
   @if $base == true {
      @return _type-base-size($font-size);
   } @else {
      @return _type-calc-font-size($font-size, $breakpoint, $typography, $rem);
   }
}


/// Function Type calc font size
///
/// Restituisce il valore corretto rem in base al breakpoint
///
/// @param {length} $font-size
/// @param {length} $breakpoint
/// @param {length} $typography
/// @param {length} $rem
@function _type-calc-font-size(
   $font-size,
   $breakpoint,
   $typography,
   $rem
) {
   // Converte px e em in rem
   @if $rem {
      @if unit($font-size) == 'em' {
         @return strip-units($font-size) * 1rem;
      }

      @else if unit($font-size) == 'px' {
         $basemap: map-get($typography, $breakpoint);
         $basefont: if(type-of($basemap) == 'list', nth($basemap, 1), $basemap);

         @return $font-size / $basefont * 1rem;
      }

      @else {
         @return $font-size;
      }
   }

   // Converte rem e px in em
   @else {
      @if unit($font-size) == 'rem' {
         @return strip-units($font-size) * 1em;
      }

      @else if unit($font-size) == 'px' {
         $basemap: map-get($typography, $breakpoint);
         $basefont: if(type-of($basemap) == 'list', nth($basemap, 1), $basemap);

         @return em($font-size, $basefont);
      }

      @else {
         @return $font-size;
      }
   }
}


/// Function Type base size
///
/// Converte in percentuale la grandezza in pixel
///
/// @param {length} $font-size
@function _type-base-size(
    $font-size
) {
    @if unit($font-size) == 'px' {
         @return $font-size / 16px * 100%;
    }

    @else {
         @error 'La dimensione dei caratteri deve essere scritta in pixel';
    }
}


/// Mixin Typography
///
/// @param {length} $target-map
/// @param {length} $breakpoints [$breakpoints]
/// @param {length} $typography [$typography]
/// @param {boolean} $base [false] - true | false
/// @param {boolean} $rem [false] - true | false
///
/// @example scss - Usage
/// $h1: (
///     null: (2em, 1.2), 
///     s: (3em, 1.3), 
///     l: 44px
/// );
/// .foo {
///    @include typography($h1);
/// }
///
/// @example css - CSS Output
/// h1 {
///     font-size: 2em;
///     line-height: 1.2
/// }
/// [@]media (min-width:30em) {
///     h1 {
///         font-size: 3em;
///         line-height: 1.3
///     }
/// }
/// [@]media (min-width:62em) {
///     h1 {
///         font-size: 2.2em
///     }
/// }
@mixin typography($target-map, $breakpoints: $breakpoints, $typography: $typography, $base: false, $rem: false) {
    $typography-map: (target: $target-map, breakpoints: $breakpoints, typography: $typography, base: $base, rem: $rem);

    @each $breakpoint, $bp-val in $target-map {
        $typography-map: map-merge($typography-map, (breakpoint: $breakpoint));

        @if $breakpoint == null {
            @include _type-write-props($typography-map);
        }

        @else if map-has-key($breakpoints, $breakpoint) {
            @include _type-breakpoint($typography-map);
        }

        @else {
            // Throws sass error
            @error $breakpoint not found in $breakpoints;
        }
    }
}


/// Typography Base
///
/// @param {length} $target-map
/// @param {length} $breakpoints [$breakpoints]
/// @param {length} $typography [$typography]
/// @param {boolean} $base [false] - true | false
/// @param {boolean} $rem [false] - true | false
///
/// @example scss - Usage
/// $typography: (
///     null: (16px, 1.5), 
///     s: 18px, 
///     l: (20px, 1.6)
/// );
/// html {
///    @include typography-base;
/// }
///
/// @example css - CSS Output
/// html {
///     font-size: 100%;
///     line-height: 1.5
/// }
/// [@]media (min-width:30em) {
///     html {
///         font-size: 112.5%
///     }
/// }
/// [@]media (min-width:62em) {
///     html {
///         font-size: 125%;
///         line-height: 1.6
///     }
/// }
@mixin typography-base($typography: $typography, $breakpoints: $breakpoints) {
    @include typography($typography, $breakpoints, $typography, true);
}


/// Function: Vertical rhythm
///
/// Possible to output both EM and REM.
/// Requires line height to be set on $typography map for 'null' breakpoint
///
/// @param {length} $vr
/// @param {length} $current-font [null]
/// @param {length} $typography [$typography]
///
/// @example scss - Usage
/// .foo {
///    margin-bottom: vr(2, 3em);
/// }
///
/// @example css - CSS Output
///
@function vr($vr, $current-font: null, $map: $typography, $mq: null, $offset: 0) {
    $base-map: map-get($map, $mq);
    $base-font: nth($base-map, 1);
    $base-line-height: null;
    $rhythm-context: null;
    $offset: strip-units(em($offset));

    @if length($base-map) <= 1 {
        @error "$typography map should contain a second argument (line-height) in the `null` key";
    }

    $base-line-height: nth($base-map, 2);
    
    @if not unit($base-line-height) == "" {
        @error "line-height multiple on the `null` key should be unitless";
    }

    $rhythm-context: $vr * ($base-line-height - $offset);
    
    // Converts into EM if $current-font is provided
    @if $current-font {
        @if unit($current-font) == "px" {
            $rhythm-context: $rhythm-context * $base-font;
            @return $rhythm-context / $current-font * 1em;
        }
        @if unit($current-font) == "em" {
            @return $rhythm-context / strip-units($current-font) * 1em;
        }
        @else {
            @error "Font sizes should either be px or em if $current-font is provided";
        }
    }
    @else {
        @return $vr * ($base-line-height - $offset) * 1rem;
    }
}


/// Mixin: Vertical rhythm
///
/// @param {number} $target
/// @param {length} $type [margin-top]
///
/// @example scss - Usage
/// .foo {
///    @include vr(2, margin-bottom);
/// }
///
/// @example css - CSS Output
/// .foo {
///     margin-bottom: 2rem;
/// }
/// [@]media (min-width:30em) {
///     .foo {
///         margin-bottom: 2rem;
///     }
/// }
/// [@]media (min-width:62em) {
///     .foo {
///         margin-bottom: 3rem;
///     }
/// }
@mixin vr($target: 1, $type: margin-top, $offset: 0px) {
    #{$type}: vr($target, $offset: $offset);
    
    $map: map-remove($typography, null);

    @each $i, $value in $map {
        @include media(">=#{$i}") {
            #{$type}: vr($target, $current-font: null, $map: $map, $mq: $i, $offset: $offset);
        }
    }
}